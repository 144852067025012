/*
 * Copyright (C) 2023 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */
@import (reference) '../../../../style/variables.less';
@import (reference) '../../../../style/mixins.less';

mitteilungen-view {
    height: 100%;
}

.mitteilungen-view,
.betreuung-mitteilung-view {
    background: @contrast-light;
    height: 100%;
    padding: 5rem 2rem 5rem;
    h1 {
        font-size: 2rem;
    }
    .message-textarea {
        height: 25rem;
        resize: none;
    }
    .bg-nummer {
        .ebeguH3();
        margin: 0;
        padding-left: 2rem;
    }
    dv-accordion {
        display: flex;
        flex-direction: column;
        overflow: visible;
        dv-accordion-tab {
            display: block;
            height: auto;
            margin-bottom: 2rem;
            width: 100%;
            &.is-sender {
                margin-left: auto;
            }
            &.institution {
                & > * {
                    color: @institution-color;
                }
            }

            &.gesuchsteller {
                & > * {
                    color: @contrast-darkest;
                }
            }

            &.gemeinde {
                & > * {
                    color: @primary;
                }
            }

            .erledigt {
                color: black;
            }
            .dv-accordion-tab-title {
                background: @contrast-lightest;
                font-size: 1.3rem;
                height: auto;
                letter-spacing: 0.15rem;
                margin: 0;
                min-height: 8rem;
                opacity: 1;
                padding: 0 2rem 0;
                transition: all 0.5s ease;
                width: 100%;
                md-checkbox {
                    margin: 0;
                }
                &:focus {
                    outline: 2px solid @contrast-darkest;
                }
                & > * {
                    display: flex;
                    flex-direction: row;
                }
                & .message-header {
                    display: flex;
                    flex: 1;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 1rem 0 1rem;

                    .subject-container {
                        align-self: stretch;
                        flex: 0 1 100%;
                    }
                    div:nth-child(2) {
                        margin-right: 1rem;
                    }
                    div:nth-child(3) {
                        margin-right: 1rem;
                    }
                    div:nth-child(4) {
                        margin-right: 1rem;
                    }
                }
                .message-delegation {
                    display: flex;
                    flex-direction: column;
                    margin: auto 3rem;
                }
                .message-checkbox {
                    display: flex;
                    flex-direction: column;
                    margin: auto 0 auto auto;
                }
                & .arrow {
                    align-items: center;
                    color: black;
                    display: flex;
                    height: auto;
                    justify-content: center;
                    min-height: 8rem;
                    text-align: center;
                    vertical-align: middle;
                    width: auto;
                    i {
                        font-size: 2.4rem;
                        transition: all 0.5s ease;
                    }
                }
                &:after {
                    background: 0;
                    border-bottom: 1px solid @contrast;
                    content: '';
                    display: block;
                    height: 0;
                    margin: 0 auto 0;
                    transition: all 0.5s ease;
                    width: 0;
                }
                &.tab-open {
                    &:after {
                        width: 100%;
                    }
                    & .arrow {
                        color: black;
                        i {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }
            .dv-accordion-tab-content {
                .ebeguP();
                background: @contrast-lightest;
                color: @contrast-darkest;
                display: block;
                height: auto;
                max-height: 60rem;
                opacity: 1;
                overflow: hidden;
                overflow-y: auto;
                padding: 0 2rem 0;
                width: 100%;
                & > tab-body > * {
                    margin: 2rem 0 3rem;
                }
                .message {
                    display: flex;
                    flex-direction: column;
                    .betreuung {
                        color: @institution-color;
                        margin-bottom: 2rem;
                        text-decoration: underline;
                    }
                }
                &.ng-hide-add {
                    max-height: 60rem;
                    overflow: hidden;
                    transition: all 0.5s ease;
                }
                &.ng-hide-add.ng-hide-add-active {
                    max-height: 0;
                    overflow: hidden;
                }
                &.ng-hide-remove {
                    max-height: 0;
                    overflow: hidden;
                    transition: all 1s ease;
                }
                &.ng-hide-remove.ng-hide-remove-active {
                    max-height: 60rem;
                    overflow: hidden;
                }
            }
        }
    }
    .mitteilungen-list {
        .mitteilung-item {
            background: transparent;
        }

        .subject {
            .ebeguBold();
            font-size: 2rem;
        }

        .message {
            .formatted {
                white-space: pre-line;
            }
            .action-buttons {
                display: flex;
                padding-left: 0;

                > div {
                    margin-right: 10px;
                }
            }
        }

        .betreuung {
            .ebeguBold();
        }

        .applied {
            margin-top: 1em;
        }
    }
}
