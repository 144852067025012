/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import '../../../../../style/variables.less';
@import '../../../../../style/mixins.less';

.dvb-error-messages-panel {
    background-color: @dred;
    border-bottom: none;
    color: @contrast-lightest;
    display: none;
    font-weight: bold;
    left: 0;
    padding: 0.5rem 0;
    position: fixed;
    right: 0;
    top: @page-start;
    z-index: @z-index-error;

    .container {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        margin-bottom: @small-abstand;
        margin-top: @small-abstand;
        .alarm-icon {
            margin-right: @small-abstand;
        }
        div {
            flex: 1;
            line-height: 2rem;
        }
        .close-icon {
            margin-left: @small-abstand;
            margin-right: @small-abstand;
        }
        .fa:before {
            line-height: 2rem;
            vertical-align: middle;
        }
        a {
            color: @color-contrast-bright-on-hover;
            line-height: 2rem;

            :hover {
                cursor: pointer;
            }
        }
    }

    a:hover {
        cursor: pointer;
    }
}

.dvb-error-messages-panel.info {
    background-color: @blue;
}
