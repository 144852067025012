/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../../../style/variables.less';
@import (reference) '../../../style/mixins.less';

.md-open-menu-container {
    box-shadow: none !important;
}

.menu-item-text {
    .menu-item-link();
    color: @contrast-lightest;
    margin-bottom: 1rem;
    padding: 0 1.5rem 0;
    &:hover {
        background: none;
    }
    * {
        padding: 0 !important;
    }
}

.md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
    line-height: 4rem !important;
    min-height: 4rem !important;
}

.menu-item-link {
    .menu-item-link();
    background: @secondary;
    color: @contrast-lightest;
    &:hover {
        background: @secondary-dark;
    }
    a {
        .menu-item-link();
        background: none;
        color: @contrast-lightest;
        &:hover {
            background: none;
            color: @contrast-lightest;
        }
    }
    .md-button {
        .menu-item-link();
        background: none;
        padding: 0 2rem 0;
        &:hover,
        &.md-default-theme:not([disabled]).md-focused,
        &:not([disabled]).md-focused,
        &:focus {
            background: none;
            box-shadow: none;
            outline: 0;
        }
    }
}

.menu-item-link.gesuchsteller {
    .menu-item-link();
    background: @primary;
    color: @contrast-lightest;
    button {
        &:hover,
        &:focus:hover {
            background: @primary-dark;
            -webkit-box-shadow: none;
            box-shadow: none;
            outline: 0;
        }
        &:focus {
            background: @primary-dark;
            -webkit-box-shadow: none;
            box-shadow: none;
            outline: 2px solid @contrast-darkest !important;
            outline-offset: -1rem;
        }
    }
    a {
        .menu-item-link();
        background: none;
        color: @contrast-lightest;
        &:hover {
            background: none;
            color: @contrast-lightest;
        }
    }
    .md-button {
        .menu-item-link();
        background: none;
        padding: 0 2rem 0;
        &:hover,
        &.md-default-theme:not([disabled]).md-focused,
        &:not([disabled]).md-focused,
        &:focus {
            background: @primary-dark;
            box-shadow: none;
            outline: 0;
        }
    }
}

.menu-item-container {
    background: @secondary;
    min-width: 15rem !important;
    padding: 1rem 0 1rem !important;
    width: auto;
    &.gesuchsteller {
        background: @primary;
    }
    &.no-padding {
        padding: 0 !important;
    }
}

.kontakt {
    span,
    a span,
    a {
        .ebeguH1();
        .ebeguNormal();
        color: @contrast-lightest;
        display: block;
        font-size: 1.6rem;
        height: auto;
        letter-spacing: 0.15rem;
        line-height: normal;
        margin: 0 2rem 0.5rem;
        text-decoration: none;
    }
    a span,
    a span:first-child,
    a span:last-child {
        margin: 0;
    }
    span:first-child {
        margin-top: 2rem;
    }
    a:last-child,
    span:last-child,
    .marginbottom {
        margin-bottom: 2rem;
    }

    .gemeindeInstitutionKontakte,
    .gemeindeSozialdienstKontakte {
        h3 {
            color: @contrast-lightest;
            margin: 2rem 2rem 0.5rem;
            letter-spacing: 0.15rem;
            font-weight: bold;
            font-size: 1.6rem;
        }
    }
}

.dossier-toolbar {
    .bold-menu {
        .ebeguBold();
        white-space: nowrap;
    }
    span {
        .menu-item-link();
        background: none;
        padding: 0 2rem 0;
        &:hover {
            background: none;
        }
    }

    md-toolbar {
        background-color: @secondary;
        height: 4rem;
        min-height: 4rem;
    }
    md-menu-bar {
        padding-left: 2rem;
        padding-right: 0;
        button {
            background: @secondary;
            display: block;
            font-size: 1.4rem;
            height: 4rem;
            min-width: 15rem;
            padding: 0 2rem 0;
            text-align: left;
            width: auto;
            span {
                padding: 0;
            }

            &:hover,
            &:focus {
                background: @secondary-dark;
                -webkit-box-shadow: none;
                box-shadow: none;
                outline: 0;
            }
        }
        md-menu {
            background: @secondary;
            display: block;
            line-height: 0;
            &:hover {
                background: @secondary;
            }
            &.md-open {
                button {
                    background: @secondary-dark;
                    &:hover {
                        background: @secondary-dark;
                    }
                }
            }
        }
    }
    md-menu-item {
        .md-button {
            padding: 0;
        }
    }
}

.dossier-toolbar-gesuchsteller {
    .gesuch-not-loaded {
        .ebeguBold();
        display: block;
        font-size: 1.6rem;
        min-width: 15rem;
        padding: 0 2rem 0;
        white-space: nowrap;
    }
    & > md-backdrop.md-sidenav-backdrop {
        z-index: (@z-index-mobile-toolbar - 1);
    }
    .gesuch-name > span {
        .ebeguBold();
        font-size: 1.6rem;
        white-space: nowrap;
    }
    .gesuch-name {
        overflow: visible;
        white-space: nowrap;
        width: 1rem;
    }

    span {
        .menu-item-link();
        background: none;
        &:hover {
            background: none;
        }
    }

    md-toolbar {
        background-color: @primary;
        height: @navbar-height;
        min-height: @navbar-height;
    }
    md-menu-bar {
        padding-left: 2rem;
        padding-right: 0;
        button {
            .ebeguBold();
            background: @primary;
            display: block;
            font-size: 1.4rem;
            height: @navbar-height;
            margin: 0;
            min-width: 15rem;
            padding: 0 2rem 0;
            text-align: left;
            white-space: nowrap;
            width: auto;
            &:hover,
            &:focus:hover {
                background: @primary-dark;
                -webkit-box-shadow: none;
                box-shadow: none;
                outline: 0;
            }
            &:focus {
                background: @primary-dark;
                -webkit-box-shadow: none;
                box-shadow: none;
                outline: 2px solid @contrast-darkest !important;
                outline-offset: -1rem;
            }
        }
        md-menu {
            background: @primary;
            line-height: 0;
            &:hover {
                background: @primary;
            }
            &.md-open {
                button {
                    background: @primary;
                    &:hover {
                        background: @primary;
                    }
                }
            }
        }
    }

    md-menu-item {
        .md-button {
            padding: 0;
        }
    }
}

.dossier-toolbar-mobile-close {
    background-color: transparent;
    color: @contrast-darkest;
    flex-shrink: 0;
    font-size: 1.8rem;
    height: @navbar-height;
    line-height: @navbar-height;
    &.active,
    &:active,
    &:hover,
    &:focus {
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: @contrast-darkest;
        outline: none !important;
        outline: 0;
        text-decoration: none !important;
    }
}

.dossier-toolbar-mobile {
    background: @primary;
    box-shadow: none;
    color: @contrast-lightest;
    max-width: 100%;
    min-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    z-index: @z-index-mobile-toolbar;
    .gesuchsperioden-container {
        display: block;
        width: 100%;
    }
    button.gesuchsperiode-button,
    button.antrag-typ-datum-button,
    .gesuchsperiode-gesuchname {
        background-color: transparent;
        border-bottom: solid @primary-dark 1px;
        color: @contrast-lightest;
        display: block;
        height: @navbar-height;
        line-height: @navbar-height;
        min-height: @navbar-height;
        padding: 0 3rem 0;
        text-align: left;
        width: 100%;
        & > span {
            .ebeguBold();
            font-size: 1.6rem;
            letter-spacing: 0.15rem;
        }
        &.active,
        &:active,
        &:hover,
        &:focus {
            background-color: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;
            color: @contrast-lightest;
            outline: none;
            outline: 0;
            text-decoration: none !important;
        }
    }
    button.gesuchsperiode-button {
        margin-top: 2rem;
    }
    button.antrag-typ-datum-button {
        padding: 0 0 0 5rem;
    }
    .gesuchsperiode-gesuchname {
        span {
            font-weight: normal;
        }
    }
    & > span {
        &.antrag-typ-datum-button,
        &.gesuchsperiode-button {
            .ebeguNormal();
            border-bottom: solid @primary-dark 1px;
            color: @contrast;
            display: block;
            font-size: 1.6rem;
            height: @navbar-height !important;
            letter-spacing: 0.15rem;
            line-height: @navbar-height !important;
            padding: 0 3rem 0;
            text-align: left;
            width: 100%;
        }
        &.flex {
            border: none;
        }
        &.antrag-typ-datum-button {
            padding: 0 5rem 0;
        }
    }

    .mobile-kontakt {
        border-bottom: solid @primary-dark 1px;
        color: @contrast-lightest;
        height: auto;
        padding: 2rem 3rem 5rem;
        text-align: left;
        width: 100%;
        & > a {
            span {
                .ebeguBold();
            }
        }
        & > button,
        & > div > button {
            padding: 0;
        }
        & > div {
            padding: 0 2rem 0;
        }

        & > span,
        a span,
        a {
            .ebeguNormal();
            color: @contrast-lightest;
            display: block;
            font-size: 1.6rem;
            height: auto;
            letter-spacing: 0.15rem;
            line-height: normal;
            text-decoration: none;
        }
    }

    .dossier-toolbar-mobile-close,
    .mobile-logout,
    .dossier-toolbar-top-operations-container {
        color: @contrast-lightest;
        height: @navbar-height;
        line-height: @navbar-height;
        text-align: right;
        width: 100%;
    }

    .dossier-toolbar-mobile-close,
    .mobile-logout {
        background-color: transparent;
        padding: 0 1.5rem 0 3rem;
        span {
            font-size: 1.6rem;
            text-transform: uppercase;
        }
        &.active,
        &:active,
        &:hover,
        &:focus {
            background-color: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;
            color: @contrast-lightest;
            outline: none;
            outline: 0;
            text-decoration: none !important;
        }
    }
    .dossier-toolbar-top-operations-container {
        background-color: @contrast-darkest;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 0 0 1rem;
        z-index: 108;
        .dossier-toolbar-mobile-close {
            background-color: transparent;
            color: @contrast-lightest;
            height: @navbar-height;
            line-height: 3rem;
            padding: 1rem;
            text-align: center;
            width: 5rem;

            &.active,
            &:active,
            &:hover,
            &:focus {
                background-color: transparent;
                -webkit-box-shadow: none;
                box-shadow: none;
                color: @contrast-lightest;
                outline: none;
                outline: 0;
                text-decoration: none !important;
            }
        }
        span {
            font-size: 1.6rem;
            text-transform: uppercase;
        }
    }
}

md-menu-bar.md-default-theme.md-open:not(.md-keyboard-mode)
    md-menu:hover
    > button,
md-menu-bar.md-open:not(.md-keyboard-mode) md-menu:hover > button {
    background: none;
}

.small-toolbar {
    height: 4rem;
    min-height: 4rem;
}

i.ellipsis-icon {
    margin-left: 1rem;
}

.font-size-2 {
    font-size: 2rem;
}

.toolbar-submenu {
    background-color: @secondary;
}

.toolbar-submenu-item {
    color: #ffffff;
    margin-bottom: 1rem;
}

.toolbar-submenu-a,
.toolbar-submenu-a:hover {
    color: #ffffff;
}

.fa-gift {
    font-size: larger;
}

.toolbar-adress {
    color: #ffffff;
    margin-left: 1rem;
}

.toolbar-gesuchname {
    .ebeguBold();
    box-sizing: border-box;
    color: @contrast-lightest;
    font-size: 1.6rem;
    height: @navbar-height;
    letter-spacing: 0.15rem;
    line-height: @navbar-height;
    padding: 0;
    text-align: center;
    text-decoration: none;
    text-transform: none;
}

md-menu-bar md-menu > button {
    color: @contrast-lightest;
}

span.margin-bottom-20 {
    display: inline-block;
}
