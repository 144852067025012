/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../../../../style/variables.less';
@import (reference) '../../../../style/mixins.less';

.dv-dokumente-list {
    .overflow-scroll-table {
        margin-bottom: 4rem;
        overflow-x: auto;
    }
    .dokumentgrundtable {
        & > tbody > tr:hover {
            background-color: transparent;
        }
    }
    table {
        background-color: transparent !important;
        border-collapse: collapse;
        margin-bottom: 0;
    }

    td.documents-vailable {
        background: @success-light;
    }
    td.no-documents {
        background: @warning-light;
    }

    td.no-color {
        background: transparent;
    }
    .sonstige {
        display: none;
    }
    .fa-circle {
        color: @warning;
        font-size: 1.1rem;
        text-align: center;
    }
    .fa-check {
        color: @success;
    }
    .check-warning {
        color: @warning;
    }

    .symbol {
        padding: 0;
        text-align: center;
        span,
        .dv-btn-operation {
            color: @contrast-darkest;
            display: block;
            height: 100%;
            width: 100%;
            &[disabled],
            &[disabled]:hover {
                color: @contrast-dark;
            }
        }
        .dv-btn-operation {
            line-height: 5rem;
            &[disabled],
            &[disabled]:hover {
                color: @contrast-dark;
            }
        }
        .dv-btn-operation:focus,
        span:focus {
            outline: 2px solid @contrast-darkest;
            outline-offset: -2px;
        }
        span {
            line-height: 3rem;
        }
    }
    .dateientable {
        font-size: 1.2rem;
        margin-bottom: 0;
        &-td,
        &-th {
            background-color: transparent;
            border: none;
            font-size: 1.2rem;
            height: 3rem !important;
            margin-bottom: 0;
        }
        &-th,
        &-th:empty {
            border-bottom: 1px solid @contrast-darkest;
            border-top: 0;
        }
        &-td > span,
        &-th > span {
            font-size: 1.2rem;
        }
    }
    table > thead:first-child > tr:first-child > th,
    table > thead > tr > th {
        .ebeguLabel();
        border: 1px solid @contrast-darkest;
        border-left: none;
        border-right: none;
        display: table-cell;
        height: 3.5rem;
        padding: 0 0.8rem 0;
        vertical-align: middle;
        span {
            .ebeguP();
            line-height: 0;
        }
    }
    table > tbody > tr > td {
        .ebeguH3();
        border: none;
        display: table-cell;
        height: 5rem;
        padding: 0 0.8rem 0;
        vertical-align: middle;
        &.dateien-spacer {
            height: 1.5rem;
        }
        &.vorgaenger {
            .ebeguItalic();
            font-weight: 600;
        }
        span {
            line-height: 0;
        }
    }

    table > tbody > tr:first-child > td {
        &.documents-vailable,
        &.no-documents,
        &.no-color {
            border-top: 1px solid @contrast-darkest;
        }
    }

    .dokumenteUploadDisabled {
        pointer-events: none;
    }
}
