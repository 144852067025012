/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

// variables and default values for them
// they are set in ColorService by JavaScript
:root {
    --primary-color: @red;
    --primary-color-dark: @dred;
    --primary-color-light: @lred;
}

@base-font-size: 10px;
//Breakpoints
@bp-xs: 600px;
@bp-sm: 960px; // 768px
@bp-md: 1025px; //992px; // 1024px
@bp-lg: 1367px; // 1366px
@bp-xlg: 1921px; // 1920px
@bp-xxlg: 2561px; // 2560px

//Media-Queries
@mq-xs: ~'only screen and (min-width: @{bp-xs})';
@mq-sm: ~'only screen and (min-width: @{bp-sm})';
@mq-md: ~'(min-width: @{bp-md})';
@mq-lg: ~'(min-width: @{bp-lg})';
@mq-xlg: ~'(min-width: @{bp-xlg}) and (max-width:@{bp-xxlg})';
@mq-xxlg: ~'(min-width: @{bp-xxlg})';
@mq-d-xs: ~'(min-device-width: @{bp-xs})';
@mq-d-md: ~'(min-device-width: @{bp-sm})';
@mq-d-lg: ~'(min-device-width: @{bp-md})';
@mq-d-xlg: ~'(min-device-width: @{bp-lg})';
@mq-d-xxlg: ~'(min-device-width: @{bp-xlg})';
@mq-d-xxxlg: ~'(min-device-width: @{bp-xxlg})';
@mq-retina: ~'(-webkit-min-device-pixel-ratio: 2) and(min-resolution: 192dpi)';
@mq-portrait: ~'and (orientation: portrait)';
@mq-landscape: ~'and (orientation: landscape)';
@mq-max-sm: ~'and (max-width: @{bp-sm})';

@background-color: @white;
@background-error-color: #ffe6e6;
@state-danger-text: red;
@floating-text-line-height: 2;
@color-contrast-hover: red;
@highlight-color: #216eff;
@grid-gutter-width: 32px;
@input-boder-focus: #337ab7;

/*Colors*/
@black: #000000;
@white: #ffffff;
@grey: #cccccc;
@red: #d50025;
@green: #16cc77;
@orange: #f5a623;
@purple: #9013fe;
@blue: #68acfc;
@anthracite: #a4a4a4;

/*Dark*/
@ddgrey: #4a4a4a;
@dgrey: #858585;
@dred: #bf0425;
@dgreen: #109457;
@dorange: #c4841c;
@dblue: #4a90e2;

/*Light*/
@lgrey: #e5e5e5;
@lred: #f0c3cb;
@lgreen: #b5e9d1;
@lorange: #f7e4c5;
@lblue: #ecf2f4;

/*Lighter*/
@llgrey: #e5e5e5;
//Theming
//Primary
@primary-light: var(--primary-color-light);
@primary: var(--primary-color);
@primary-dark: var(--primary-color-dark);
//Secondary
@secondary-light: @lblue;
@secondary: @blue;
@secondary-dark: @dblue;

@secondary-button: #cfcfcf;
@secondary-button-hover: #a8a8a8;

//Institutionen
@institution-color: #5aac95;
//Contrast
@contrast-darkest: @black;
@contrast-darker: @ddgrey;
@contrast-dark: @dgrey;
@contrast: @grey;
@contrast-light: @lgrey;
@contrast-lighter: @llgrey;
@contrast-lightest: @white;
//Success
@success-light: @lgreen;
@success: @green;
@success-dark: @dgreen;
//Warning
@warning-light: @lorange;
@warning: @orange;
@warning-dark: @dorange;
//Danger
@danger-light: @lred;
@danger: @red;
@danger-dark: @dred;

/* Contrast Color für Error Handling */
@color-contrast-hue: 359;
@color-contrast: hsl(@color-contrast-hue, 100%, 67%);
@color-contrast-hover: hsl(@color-contrast-hue, 100%, 67%);
@color-contrast-normal: hsl(@color-contrast-hue, 100%, 74%);
@color-contrast-middle: hsl(@color-contrast-hue, 100%, 87%);
@color-contrast-bright: hsl(@color-contrast-hue, 100%, 94%);
@color-contrast-bright-on-hover: white;

/* Abstände */
@small-abstand: 1rem;
@smaller-abstand: @small-abstand / 2;
@normal-abstand: 2 * @small-abstand;
@big-abstand: 5 * @small-abstand;
@padding-base-horizontal: @small-abstand;
@padding-base-vertical: @small-abstand;

/*Navbar */
@navbar-height: 5rem;

@sidebar-height: 5rem;

/*Position*/
@page-start: (@navbar-height*2); //40px fuer intro

/*z-index*/
@z-index-navbar: 102;
@z-index-dashboard: 105;
@z-index-menu: 105;
@z-index-progressbar: 103;
@z-index-sidenav: 101;
@z-index-mobile-toolbar: 107;
@z-index-kommentar: 101;
@z-index-toolbar: 104;
@z-index-dialog: 110;
@z-index-error: 106;
