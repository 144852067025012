/*
 * Copyright (C) 2023 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

.verfuegen-view {
    .result {
        background-color: #d9d9d9;
        font-weight: bold;
    }

    .table.verfuegung-table {
        th,
        td {
            text-align: right;
        }

        tbody > tr:hover .result {
            background-color: transparent;
        }

        .infotext-row {
            td {
                border-bottom: none;
                vertical-align: bottom;
            }
        }

        .title-row {
            th {
                border-top: none;
                text-align: right;
                vertical-align: top;
                width: 10%;
            }
        }
    }
    .white-space-pre-wrap {
        white-space: pre-wrap;
    }
}
