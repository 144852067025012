/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../../../../style/variables.less';
@import (reference) '../../../../style/mixins.less';

dv-accordion {
    overflow: hidden;
    dv-accordion-tab {
        .dv-accordion-tab-title {
            background: darkgrey;
            color: black;
            display: block;
            margin: 0;
            position: relative;
            -webkit-touch-callout: default; /* iOS Safari */
            transition: none !important;
            -webkit-user-select: text; /* Safari*/
            -khtml-user-select: text; /* Konqueror */
            -moz-user-select: text; /* Firefox */
            -ms-user-select: text; /* Internet Explorer/Edge */
            user-select: text;
            width: 100%;
            z-index: 1;
            &:focus {
                outline: 2px solid @contrast-darkest;
            }
            &.tab-open {
                background: grey;
                color: black;
                dv-tooltip {
                    i {
                        color: white;
                    }
                }
            }
        }
        .dv-accordion-tab-content {
            background: lightblue;
            display: block;
            height: auto;
            max-height: 15rem;
            overflow: hidden;
            width: 100%;
            &.ng-hide-add {
                max-height: 15rem;
                transition: none !important;
            }
            &.ng-hide-add.ng-hide-add-active {
                max-height: 0;
            }
            &.ng-hide-remove {
                max-height: 0;
                transition: all 1s ease;
            }
            &.ng-hide-remove.ng-hide-remove-active {
                max-height: 15rem;
            }
        }
    }
}
