/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../../../../style/variables.less';
@import (reference) '../../../../style/mixins.less';

.dv-error-messages,
dv-error-messages {
    box-sizing: border-box;
    color: rgb(221, 44, 0);
    font-size: 1.4rem;
    line-height: 3rem;
}

dv-error-messages {
    display: block;
}

dv-error-messages.date {
    display: inline-block;
}

.form-group {
    dv-error-messages {
        margin-bottom: -3rem;
    }
}

.dv-error-messages {
    display: block;
    min-height: 3rem;
    margin: 0;
    min-width: 1px;
    overflow: visible;
    & > div > div {
        box-sizing: border-box;
        padding: 0;
        vertical-align: middle;
    }
}

md-input-container .md-errors-spacer,
.md-errors-spacer {
    display: none;
    height: 2.5rem;
    min-height: 2.5rem !important;
}
