/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/* Component LESS */
@import (reference) '../../style/variables.less';
@import (reference) '../../style/mixins.less';

/* Add Component LESS Above */
.ebegu {
    //smart table
    .st-selected,
    .table-striped .st-selected {
        background: transparent;
        color: white;
    }

    .view-title {
        hr.header {
            margin-bottom: 5rem;
        }
        p:last-child {
            margin-bottom: 5rem;
        }
    }

    .view-sub-title {
        hr.header {
            margin-bottom: 1rem;
        }
        p:last-child {
            margin-bottom: 1rem;
        }
    }

    .inline-hint {
        .ebeguItalic();
        font-size: 1.4rem;
        line-height: 2.5rem;
    }

    //SmartTable Styles
    .table > thead > tr:first-child > th.sortable,
    .table > thead:first-child > tr:first-child > th.sortable {
        cursor: pointer;
        padding-right: 0;
        white-space: nowrap;
    }

    .sortable {
        opacity: 1;

        span {
            position: relative;
            top: -5px;
        }

        &:hover {
            svg {
                opacity: 0.6;
            }
        }

        svg {
            opacity: 0;
            width: 24px;
            height: 24px;
            fill: var(--mat-sort-arrow-color, var(--mat-sys-on-surface));
            transform: scale(0.8);
            position: relative;
            top: 2px;
        }

        &:after {
            content: none; /* Hide default */
        }

        &.st-sort-ascent {
            svg {
                opacity: 1;
                transform: scale(0.8);
            }
        }

        &.st-sort-descent {
            svg {
                opacity: 1;
                transform: scale(0.8) rotate(180deg);
            }
        }
    }

    .pagination {
        .active {
            a {
                background-color: @primary;
                border-color: @primary-dark;
                color: @contrast-lightest;

                &:hover,
                &:focus {
                    background-color: @primary-dark;
                    border-color: @primary;
                    color: @contrast-lightest;
                }
            }
        }

        a {
            color: @primary;

            &,
            &:first-child,
            &:last-child {
                border-radius: 0;
            }

            &:hover,
            &:focus {
                color: @primary-dark;
            }
        }
    }

    hr.header {
        background-color: #333;
        border: none;
        color: #333;
        height: 0.1rem;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        min-height: 1px;
    }

    .margin-left-50 {
        margin-left: 5rem;
    }

    .margin-left-10 {
        margin-left: 1rem;
    }

    .margin-left-5 {
        margin-left: 0.5rem;
    }

    .margin-left-20 {
        margin-left: 2rem;
    }

    .no-margin-y {
        margin-bottom: 0;
        margin-top: 0;
    }
    .no-margin {
        margin: 0;
    }

    .margin-top-05 {
        margin-top: 0.5rem;
    }

    .margin-top-10 {
        margin-top: 1rem;
    }

    .margin-top-20 {
        margin-top: 2rem;
    }

    .margin-top-30 {
        margin-top: 3rem;
    }

    .margin-top-40 {
        margin-top: 4rem;
    }

    .margin-top-50 {
        margin-top: 5rem;
    }

    .margin-bottom-10 {
        margin-bottom: 1rem;
    }

    .margin-bottom-20 {
        margin-bottom: 2rem;
    }

    .margin-bottom-30 {
        margin-bottom: 3rem;
    }

    .margin-bottom-50 {
        margin-bottom: 5rem;
    }

    .margin-bottom-60 {
        margin-bottom: 5rem;
    }

    .margin-bottom-80 {
        margin-bottom: 8rem;
    }

    .padding-left-60 {
        padding-left: 6rem;
    }

    .padding-left-50 {
        padding-left: 5rem;
    }

    .padding-left-40 {
        padding-left: 4rem;
    }

    .padding-left-30 {
        padding-left: 3rem;
    }

    .padding-left-20 {
        padding-left: 2rem;
    }

    .padding-left-10 {
        padding-left: 1rem;
    }

    .padding-left-5 {
        padding-left: 0.5rem;
    }

    .padding-left-0 {
        padding-left: 0rem;
    }

    .padding-top-05 {
        padding-top: 0.5rem;
    }

    .padding-top-10 {
        padding-top: 1rem;
    }

    .padding-top-20 {
        padding-top: 2rem;
    }

    .padding-top-30 {
        padding-top: 3rem;
    }

    .padding-top-40 {
        padding-top: 4rem;
    }

    .padding-top-50 {
        padding-top: 5rem;
    }

    .padding-bottom-10 {
        padding-bottom: 1rem;
    }

    .padding-bottom-20 {
        padding-bottom: 2rem;
    }

    .padding-bottom-30 {
        padding-bottom: 3rem;
    }

    .padding-bottom-50 {
        padding-bottom: 5rem;
    }

    .padding-bottom-60 {
        padding-bottom: 5rem;
    }

    .padding-bottom-80 {
        padding-bottom: 8rem;
    }

    .margin-bottom-3rem {
        margin-bottom: 3rem;
    }

    //errors sofort nach verlassen des feldes anzeigen oder sobald das form submitted wurde
    form:not(.ng-submitted) .form-group {
        //geschwisterelement von .formcontrol feldern oder .input-element feldern sollen Fehlermeldung noch nicht anzeigen wenn input untouched ist
        .form-control.ng-untouched
            ~ dv-error-messages
            > .dv-error-messages
            > .error,
        .input-element.ng-untouched
            ~ dv-error-messages
            > .dv-error-messages
            > .error,
        .form-group.ng-untouched
            ~ dv-error-messages
            > .dv-error-messages
            > .error,
        .md-input-container.ng-untouched
            ~ dv-error-messages
            > .dv-error-messages
            > .error,
        .input-element.ng-untouched ~ .dv-error-messages > .error,
        input[type='text'].ng-untouched ~ .dv-error-messages > .error {
            display: none;
        }
    }

    // aktuell gibt es eingentlich keine hints mehr
    // Damit wir unter den inputs hints anzeigen koennen schieben wir die errors noch bisschen runter wenn ein Hint existiert
    .hint {
        .ebeguItalic();
        bottom: 0.7rem;
        color: @contrast-darkest;
        font-size: 1.4rem;
        left: 0.2rem;
        letter-spacing: 0.135rem;
        line-height: 2.5rem;
        position: absolute;
        right: auto;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    }

    md-input-container .error.hint [ng-messages] {
        /* position: relative;
         -webkit-order: 4;
         -ms-flex- order: 4;
         order: 4;
         overflow: hidden;
         top: 1.6rem;
         clear: left;*/
    }

    /*stylelint-disable selector-class-pattern */
    ._md-open-menu-container,
    .md-open-menu-container {
        /*stylelint-enable */
        z-index: @z-index-error !important;
    }

    .well {
        border: none;
        border-bottom: 0.5rem solid black;
        border-radius: 0;
        box-shadow: none;
        padding: 2rem 2.5rem 2rem 5rem;
        span:not(
                .mat-checkbox-inner-container,
                .mat-checkbox-background,
                .mat-ripple,
                .mat-ripple-element,
                .mat-checkbox-frame,
                .mat-checkbox-label
            ) {
            .ebeguH3();
        }
        i {
            padding: 1rem 1rem 1rem 0;
        }
    }

    .well-status-warten {
        background-color: @warning-light;
        border-bottom-color: @warning;
        i {
            color: @warning;
        }
    }

    .well-status-bestaetigt {
        background-color: @success-light;
        border-bottom-color: @success;
        i {
            color: @success;
        }
    }

    .well-status-abgewiesen {
        background-color: @danger-light;
        border-bottom-color: @danger;
        i {
            color: @danger;
        }
    }

    .required:after {
        content: '\00a0*';
        display: inline;
    }
    h1.required span:after,
    .ebegu-h1.required dv-tooltip:after {
        content: '*';
    }
    h1.required:after,
    .ebegu-h1.required:after {
        content: '';
    }
    .md-required:after {
        content: '\00a0*' !important;
    }
}

.dv-input-container-question {
    h1,
    .ebegu-h1 {
        line-height: 2.4rem;
    }
}
