/*
 * Copyright (C) 2018 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
@import (reference) './mixins.less';
@import (reference) './variables.less';

/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 */

/* Theme for the ripple elements.*/

/* stylelint-disable material/no-prefixes */

/* stylelint-disable */
.mat-elevation-z0 {
    box-shadow:
        0 0 0 0 rgba(0, 0, 0, 0.2),
        0 0 0 0 rgba(0, 0, 0, 0.14),
        0 0 0 0 rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
    box-shadow:
        0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
    box-shadow:
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
    box-shadow:
        0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14),
        0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
    box-shadow:
        0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
    box-shadow:
        0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 5px 8px 0 rgba(0, 0, 0, 0.14),
        0 1px 14px 0 rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
    box-shadow:
        0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
    box-shadow:
        0 4px 5px -2px rgba(0, 0, 0, 0.2),
        0 7px 10px 1px rgba(0, 0, 0, 0.14),
        0 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
    box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
    box-shadow:
        0 5px 6px -3px rgba(0, 0, 0, 0.2),
        0 9px 12px 1px rgba(0, 0, 0, 0.14),
        0 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
    box-shadow:
        0 6px 6px -3px rgba(0, 0, 0, 0.2),
        0 10px 14px 1px rgba(0, 0, 0, 0.14),
        0 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
    box-shadow:
        0 6px 7px -4px rgba(0, 0, 0, 0.2),
        0 11px 15px 1px rgba(0, 0, 0, 0.14),
        0 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
    box-shadow:
        0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 12px 17px 2px rgba(0, 0, 0, 0.14),
        0 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
    box-shadow:
        0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 13px 19px 2px rgba(0, 0, 0, 0.14),
        0 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
    box-shadow:
        0 7px 9px -4px rgba(0, 0, 0, 0.2),
        0 14px 21px 2px rgba(0, 0, 0, 0.14),
        0 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
    box-shadow:
        0 8px 9px -5px rgba(0, 0, 0, 0.2),
        0 15px 22px 2px rgba(0, 0, 0, 0.14),
        0 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
    box-shadow:
        0 8px 10px -5px rgba(0, 0, 0, 0.2),
        0 16px 24px 2px rgba(0, 0, 0, 0.14),
        0 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
    box-shadow:
        0 8px 11px -5px rgba(0, 0, 0, 0.2),
        0 17px 26px 2px rgba(0, 0, 0, 0.14),
        0 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
    box-shadow:
        0 9px 11px -5px rgba(0, 0, 0, 0.2),
        0 18px 28px 2px rgba(0, 0, 0, 0.14),
        0 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
    box-shadow:
        0 9px 12px -6px rgba(0, 0, 0, 0.2),
        0 19px 29px 2px rgba(0, 0, 0, 0.14),
        0 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
    box-shadow:
        0 10px 13px -6px rgba(0, 0, 0, 0.2),
        0 20px 31px 3px rgba(0, 0, 0, 0.14),
        0 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
    box-shadow:
        0 10px 13px -6px rgba(0, 0, 0, 0.2),
        0 21px 33px 3px rgba(0, 0, 0, 0.14),
        0 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
    box-shadow:
        0 10px 14px -6px rgba(0, 0, 0, 0.2),
        0 22px 35px 3px rgba(0, 0, 0, 0.14),
        0 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
    box-shadow:
        0 11px 14px -7px rgba(0, 0, 0, 0.2),
        0 23px 36px 3px rgba(0, 0, 0, 0.14),
        0 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
    box-shadow:
        0 11px 15px -7px rgba(0, 0, 0, 0.2),
        0 24px 38px 3px rgba(0, 0, 0, 0.14),
        0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
    font:
        400 24px/32px Roboto,
        'Helvetica Neue',
        sans-serif;
    margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography h2 {
    font:
        500 20px/32px Roboto,
        'Helvetica Neue',
        sans-serif;
    margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
    font:
        400 16px/28px Roboto,
        'Helvetica Neue',
        sans-serif;
    margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
    font:
        400 15px/24px Roboto,
        'Helvetica Neue',
        sans-serif;
    margin: 0 0 16px;
}

.mat-h5,
.mat-typography h5 {
    font:
        400 11.62px/20px Roboto,
        'Helvetica Neue',
        sans-serif;
    margin: 0 0 12px;
}

.mat-h6,
.mat-typography h6 {
    font:
        400 9.38px/20px Roboto,
        'Helvetica Neue',
        sans-serif;
    margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2 {
    font:
        500 14px/24px Roboto,
        'Helvetica Neue',
        sans-serif;
}

.mat-body,
.mat-body-1,
.mat-typography {
    font:
        400 14px/20px Roboto,
        'Helvetica Neue',
        sans-serif;
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
    margin: 0 0 12px;
}

.mat-small,
.mat-caption {
    font:
        400 12px/20px Roboto,
        'Helvetica Neue',
        sans-serif;
}

.mat-display-4,
.mat-typography .mat-display-4 {
    font:
        300 112px/112px Roboto,
        'Helvetica Neue',
        sans-serif;
    letter-spacing: -0.05em;
    margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
    font:
        400 56px/56px Roboto,
        'Helvetica Neue',
        sans-serif;
    letter-spacing: -0.02em;
    margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
    font:
        400 45px/48px Roboto,
        'Helvetica Neue',
        sans-serif;
    letter-spacing: -0.005em;
    margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
    font:
        400 34px/40px Roboto,
        'Helvetica Neue',
        sans-serif;
    margin: 0 0 64px;
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.mat-mdc-button-toggle {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-card {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-card-title {
    font-size: 24px;
    font-weight: 400;
}

.mat-card-subtitle,
.mat-card-content,
.mat-card-header .mat-card-title {
    font-size: 14px;
}

.mat-checkbox {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
    line-height: 24px;
}

.mat-chip {
    font-size: 13px;
    line-height: 18px;
}

.mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px;
}

.mat-table {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-header-cell {
    font-size: 12px;
    font-weight: 500;
}

.mat-cell {
    font-size: 14px;
}

.mat-calendar {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-calendar-body {
    font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
    font-size: 14px;
    font-weight: 500;
}

.mat-calendar-table-header th {
    font-size: 11px;
    font-weight: 400;
}

.mat-mdc-dialog-title {
    font:
        500 20px/32px Roboto,
        'Helvetica Neue',
        sans-serif;
}

.mat-expansion-panel-header {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.mat-expansion-panel-content {
    font:
        400 14px/20px Roboto,
        'Helvetica Neue',
        sans-serif;
}

.mat-form-field {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
}

.mat-mdc-text-field-wrapper {
    padding-bottom: 1.25em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
    font-size: 150%;
    line-height: 1.125;
}

.mat-form-field-prefix .mat-mdc-icon-button,
.mat-form-field-suffix .mat-mdc-icon-button {
    height: 1.5em;
    width: 1.5em;
}

.mat-form-field-prefix .mat-mdc-icon-button .mat-icon,
.mat-form-field-suffix .mat-mdc-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125;
}

.mat-form-field-infix {
    border-top: 0.84375em solid transparent;
    padding: 0.4375em 0;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    -ms-transform: translateY(-1.28125em) scale(0.75);
    transform: translateY(-1.28125em) scale(0.75) perspective(100px)
        translateZ(0.001px);
    width: 133.33333%;
}

.mat-form-field-can-float
    .mat-form-field-autofill-control:-webkit-autofill
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    -ms-transform: translateY(-1.28124em) scale(0.75);
    transform: translateY(-1.28125em) scale(0.75) perspective(100px)
        translateZ(0.00101px);
    width: 133.33334%;
}

.mat-form-field-can-float
    .mat-input-server[label]:not(:label-shown)
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    -ms-transform: translateY(-1.28123em) scale(0.75);
    transform: translateY(-1.28125em) scale(0.75) perspective(100px)
        translateZ(0.00102px);
    width: 133.33335%;
}

.mat-form-field-label-wrapper {
    padding-top: 0.84375em;
    top: -0.84375em;
}

.mat-form-field-label {
    top: 1.28125em;
}

.mat-form-field-underline {
    bottom: 1.25em;
}

.mat-form-field-subscript-wrapper {
    font-size: 75%;
    margin-top: 0.54167em;
    top: calc(100% - 1.66667em);
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
    font-size: 14px;
}

.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mat-grid-tile-header .mat-line:nth-child(n + 2),
.mat-grid-tile-footer .mat-line:nth-child(n + 2) {
    font-size: 12px;
}

input.mat-input-element {
    margin-top: -0.0625em;
}

.mat-menu-item {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 12px;
}

.mat-radio-button {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-radio-group label {
    display: inline-flex;
}

.mat-select {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-select-trigger {
    height: 1.125em;
}

.form-group .mat-select-trigger {
    line-height: 4rem;
}

.mat-slide-toggle-content {
    font:
        400 14px/20px Roboto,
        'Helvetica Neue',
        sans-serif;
}

.mat-slider-thumb-label-text {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-weight: 500;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-step-label {
    font-size: 14px;
    font-weight: 400;
}

.mat-step-label-selected {
    font-size: 14px;
    font-weight: 500;
}

.mat-tab-group {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-tab-label,
.mat-tab-link {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
    font:
        500 20px/32px Roboto,
        'Helvetica Neue',
        sans-serif;
    margin: 0;
}

.mat-tooltip {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 10px;
    padding-bottom: 6px;
    padding-top: 6px;
}

.mat-tooltip-handset {
    font-size: 14px;
    padding-bottom: 9px;
    padding-top: 9px;
}

.mat-list-item {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-list-option {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-list .mat-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item {
    font-size: 16px;
}

.mat-list .mat-list-item .mat-line,
.mat-nav-list .mat-list-item .mat-line,
.mat-selection-list .mat-list-item .mat-line {
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mat-list .mat-list-item .mat-line:nth-child(n + 2),
.mat-nav-list .mat-list-item .mat-line:nth-child(n + 2),
.mat-selection-list .mat-list-item .mat-line:nth-child(n + 2) {
    font-size: 14px;
}

.mat-list .mat-list-option,
.mat-nav-list .mat-list-option,
.mat-selection-list .mat-list-option {
    font-size: 16px;
}

.mat-list .mat-list-option .mat-line,
.mat-nav-list .mat-list-option .mat-line,
.mat-selection-list .mat-list-option .mat-line {
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mat-list .mat-list-option .mat-line:nth-child(n + 2),
.mat-nav-list .mat-list-option .mat-line:nth-child(n + 2),
.mat-selection-list .mat-list-option .mat-line:nth-child(n + 2) {
    font-size: 14px;
}

.mat-list .mat-subheader,
.mat-nav-list .mat-subheader,
.mat-selection-list .mat-subheader {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.mat-list[dense] .mat-list-item,
.mat-nav-list[dense] .mat-list-item,
.mat-selection-list[dense] .mat-list-item {
    font-size: 12px;
}

.mat-list[dense] .mat-list-item .mat-line,
.mat-nav-list[dense] .mat-list-item .mat-line,
.mat-selection-list[dense] .mat-list-item .mat-line {
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mat-list[dense] .mat-list-item .mat-line:nth-child(n + 2),
.mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n + 2),
.mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n + 2) {
    font-size: 12px;
}

.mat-list[dense] .mat-list-option,
.mat-nav-list[dense] .mat-list-option,
.mat-selection-list[dense] .mat-list-option {
    font-size: 12px;
}

.mat-list[dense] .mat-list-option .mat-line,
.mat-nav-list[dense] .mat-list-option .mat-line,
.mat-selection-list[dense] .mat-list-option .mat-line {
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mat-list[dense] .mat-list-option .mat-line:nth-child(n + 2),
.mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n + 2),
.mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n + 2) {
    font-size: 12px;
}

.mat-list[dense] .mat-subheader,
.mat-nav-list[dense] .mat-subheader,
.mat-selection-list[dense] .mat-subheader {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-weight: 500;
}

.mat-option {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 16px;
}

.mat-optgroup-label {
    font:
        500 14px/24px Roboto,
        'Helvetica Neue',
        sans-serif;
}

.mat-simple-snackbar {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
}

.mat-simple-snackbar-action {
    font-family: inherit;
    font-size: inherit;
    font-weight: 500;
    line-height: 1;
}

.mat-ripple {
    overflow: hidden;
}

@media screen and (-ms-high-contrast: active) {
    .mat-ripple {
        display: none;
    }
}

.mat-ripple.mat-ripple-unbounded {
    overflow: visible;
}

.mat-ripple-element {
    border-radius: 50%;
    pointer-events: none;
    position: absolute;
    transform: scale(0);
    transition:
        opacity,
        transform 0ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-visually-hidden {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    outline: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    height: 100%;
    left: 0;
    pointer-events: none;
    top: 0;
    width: 100%;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1000;
}

.cdk-overlay-container:empty {
    display: none;
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
}

.cdk-overlay-pane {
    box-sizing: border-box;
    pointer-events: auto;
    position: absolute;
    z-index: 1000;

    &:has(> .mat-mdc-select-panel) {
        margin-top: -22px;
    }
}

.cdk-overlay-backdrop {
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: auto;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 1000;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.288);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0;
}

.cdk-global-scrollblock {
    overflow-y: scroll;
    position: fixed;
    width: 100%;
}

.mat-ripple-element {
    background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
    color: rgba(0, 0, 0, 0.87);
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #d50000;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #424242;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #f44336;
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04);
}

.mat-option.mat-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
}

.mat-option.mat-option-disabled {
    color: rgba(0, 0, 0, 0.38);
}

.mat-optgroup-label {
    color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
    color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
    color: rgba(0, 0, 0, 0.54);
}

.mat-pseudo-checkbox::after {
    color: #fafafa;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
    background: #424242;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
    background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
    background: #b0b0b0;
}

.mat-app-background {
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.87);
}

.mat-theme-loaded-marker {
    display: none;
}

.mat-autocomplete-panel {
    background: white;
    color: rgba(0, 0, 0, 0.87);
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: white;
}

.mat-autocomplete-panel
    .mat-option.mat-selected:not(.mat-active):not(:hover):not(
        .mat-option-disabled
    ) {
    color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-stroked-button {
    background: transparent;
}

.mat-mdc-button.mat-primary .mat-mdc-button-focus-overlay,
.mat-mdc-icon-button.mat-primary .mat-mdc-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-mdc-button-focus-overlay {
    background-color: rgba(213, 0, 0, 0.12);
}

.mat-mdc-button.mat-accent .mat-mdc-button-focus-overlay,
.mat-mdc-icon-button.mat-accent .mat-mdc-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-mdc-button-focus-overlay {
    background-color: rgba(66, 66, 66, 0.12);
}

.mat-mdc-button.mat-warn .mat-mdc-button-focus-overlay,
.mat-mdc-icon-button.mat-warn .mat-mdc-button-focus-overlay,
.mat-stroked-button.mat-warn .mat-mdc-button-focus-overlay {
    background-color: rgba(244, 67, 54, 0.12);
}

.mat-mdc-button[disabled] .mat-mdc-button-focus-overlay,
.mat-mdc-icon-button[disabled] .mat-mdc-button-focus-overlay,
.mat-stroked-button[disabled] .mat-mdc-button-focus-overlay {
    background-color: transparent;
}

.mat-mdc-button.mat-primary,
.mat-mdc-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
    color: #d50000;
}

.mat-mdc-button.mat-accent,
.mat-mdc-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
    color: #424242;
}

.mat-mdc-button.mat-warn,
.mat-mdc-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
    color: #f44336;
}

.mat-mdc-button.mat-primary[disabled],
.mat-mdc-button.mat-accent[disabled],
.mat-mdc-button.mat-warn[disabled],
.mat-mdc-button[disabled][disabled],
.mat-mdc-icon-button.mat-primary[disabled],
.mat-mdc-icon-button.mat-accent[disabled],
.mat-mdc-icon-button.mat-warn[disabled],
.mat-mdc-icon-button[disabled][disabled],
.mat-stroked-button.mat-primary[disabled],
.mat-stroked-button.mat-accent[disabled],
.mat-stroked-button.mat-warn[disabled],
.mat-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26);
}

.mat-raised-button,
.mat-fab,
.mat-mini-fab {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
}

.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    color: white;
}

.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
    color: white;
}

.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
    color: white;
}

.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26);
}

.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background-color: #d50000;
}

.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
    background-color: #424242;
}

.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
    background-color: #f44336;
}

.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12);
}

.mat-raised-button.mat-primary .mat-ripple-element,
.mat-fab.mat-primary .mat-ripple-element,
.mat-mini-fab.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.2);
}

.mat-raised-button.mat-accent .mat-ripple-element,
.mat-fab.mat-accent .mat-ripple-element,
.mat-mini-fab.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.2);
}

.mat-raised-button.mat-warn .mat-ripple-element,
.mat-fab.mat-warn .mat-ripple-element,
.mat-mini-fab.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.2);
}

.mat-mdc-button.mat-primary .mat-ripple-element {
    background-color: rgba(213, 0, 0, 0.1);
}

.mat-mdc-button.mat-accent .mat-ripple-element {
    background-color: rgba(66, 66, 66, 0.1);
}

.mat-mdc-button.mat-warn .mat-ripple-element {
    background-color: rgba(244, 67, 54, 0.1);
}

.mat-flat-button {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
}

.mat-flat-button.mat-primary {
    color: white;
}

.mat-flat-button.mat-accent {
    color: white;
}

.mat-flat-button.mat-warn {
    color: white;
}

.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26);
}

.mat-flat-button.mat-primary {
    background-color: #d50000;
}

.mat-flat-button.mat-accent {
    background-color: #424242;
}

.mat-flat-button.mat-warn {
    background-color: #f44336;
}

.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.2);
}

.mat-flat-button.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.2);
}

.mat-flat-button.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.2);
}

.mat-mdc-icon-button.mat-primary .mat-ripple-element {
    background-color: rgba(213, 0, 0, 0.2);
}

.mat-mdc-icon-button.mat-accent .mat-ripple-element {
    background-color: rgba(66, 66, 66, 0.2);
}

.mat-mdc-icon-button.mat-warn .mat-ripple-element {
    background-color: rgba(244, 67, 54, 0.2);
}

.mat-mdc-button-toggle {
    color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-button-toggle.cdk-focused .mat-mdc-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button-toggle-checked {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-button-toggle-disabled {
    background-color: #eeeeee;
    color: rgba(0, 0, 0, 0.26);
}

.mat-mdc-button-toggle-disabled.mat-mdc-button-toggle-checked {
    background-color: #bdbdbd;
}

.mat-card {
    background: white;
    color: rgba(0, 0, 0, 0.87);
}

.mat-card-subtitle {
    color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
    border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
    fill: #fafafa;
}

.mat-checkbox-checkmark-path {
    stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
    background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: #d50000;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #424242;
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
.mat-checkbox-checked.mat-warn .mat-checkbox-background {
    background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: #b0b0b0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: #b0b0b0;
}

.mat-checkbox-disabled .mat-checkbox-label {
    color: #b0b0b0;
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary
    .mat-checkbox-ripple
    .mat-ripple-element {
    background-color: rgba(213, 0, 0, 0.26);
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent
    .mat-checkbox-ripple
    .mat-ripple-element {
    background-color: rgba(66, 66, 66, 0.26);
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn
    .mat-checkbox-ripple
    .mat-ripple-element {
    background-color: rgba(244, 67, 54, 0.26);
}

.mat-chip:not(.mat-basic-chip) {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
}

.mat-chip:not(.mat-basic-chip) .mat-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4;
}

.mat-chip:not(.mat-basic-chip) .mat-chip-remove:hover {
    opacity: 0.54;
}

.mat-chip.mat-chip-selected.mat-primary {
    background-color: #d50000;
    color: white;
}

.mat-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: white;
    opacity: 0.4;
}

.mat-chip.mat-chip-selected.mat-primary .mat-chip-remove:hover {
    opacity: 0.54;
}

.mat-chip.mat-chip-selected.mat-warn {
    background-color: #f44336;
    color: white;
}

.mat-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: white;
    opacity: 0.4;
}

.mat-chip.mat-chip-selected.mat-warn .mat-chip-remove:hover {
    opacity: 0.54;
}

.mat-chip.mat-chip-selected.mat-accent {
    background-color: #424242;
    color: white;
}

.mat-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: white;
    opacity: 0.4;
}

.mat-chip.mat-chip-selected.mat-accent .mat-chip-remove:hover {
    opacity: 0.54;
}

.mat-table {
    background: white;
}

.mat-row,
.mat-header-row {
    border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
    color: rgba(0, 0, 0, 0.54);
}

.mat-cell {
    color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
    border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-next-button,
.mat-calendar-previous-button {
    color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
    color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
    background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
    color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
    border-color: transparent;
    color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-body-disabled
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    color: rgba(0, 0, 0, 0.38);
}

:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: rgba(0, 0, 0, 0.04);
}

.mat-calendar-body-selected {
    background-color: #d50000;
    color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(213, 0, 0, 0.4);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white;
}

.mat-calendar-body-disabled
    > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: rgba(0, 0, 0, 0.18);
}

.mat-datepicker-toggle-active {
    color: #d50000;
}

.mat-mdc-dialog-container {
    background: white;
    color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
    border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
    border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
    background: white;
    color: rgba(0, 0, 0, 0.87);
}

.mat-action-row {
    border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:not(
        [aria-disabled='true']
    ).cdk-keyboard-focused,
.mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused,
.mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:not([aria-disabled='true']):hover {
    background: rgba(0, 0, 0, 0.04);
}

.mat-expansion-panel-header-title {
    color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
    color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled='true'] {
    color: rgba(0, 0, 0, 0.26);
}

.mat-expansion-panel-header[aria-disabled='true']
    .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled='true']
    .mat-expansion-panel-header-description {
    color: inherit;
}

.mat-form-field-label {
    color: rgba(0, 0, 0, 0.54);
}

.mat-hint {
    color: rgba(0, 0, 0, 0.54);
}

.mat-focused .mat-form-field-label {
    color: #d50000;
}

.mat-focused .mat-form-field-label.mat-accent {
    color: #424242;
}

.mat-focused .mat-form-field-label.mat-warn {
    color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
    color: #424242;
}

.mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.42) 0%,
        rgba(0, 0, 0, 0.42) 33%,
        transparent 0%
    );
    background-repeat: repeat-x;
    background-size: 4px 1px;
}

.mat-form-field-ripple {
    background-color: #d50000;
}

.mat-form-field-ripple.mat-accent {
    background-color: #424242;
}

.mat-form-field-ripple.mat-warn {
    background-color: #f44336;
}

.mat-form-field-invalid .mat-form-field-label {
    color: #f44336;
}

.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #f44336;
}

.mat-form-field-invalid .mat-form-field-ripple {
    background-color: #f44336;
}

.mat-error {
    color: #f44336;
    font-size: 1.4rem;
}

.mat-icon.mat-primary {
    color: #d50000;
}

.mat-icon.mat-accent {
    color: #424242;
}

.mat-icon.mat-warn {
    color: #f44336;
}

.mat-input-element:disabled {
    color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
    caret-color: #d50000;
}

.mat-input-element::placeholder {
    color: rgba(0, 0, 0, 0.42);
}

.mat-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, 0.42);
}

.mat-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.42);
}

.mat-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.42);
}

.mat-accent .mat-input-element {
    caret-color: #424242;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
    caret-color: #f44336;
}

.mat-list .mat-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item {
    color: rgba(0, 0, 0, 0.87);
}

.mat-list .mat-list-option,
.mat-nav-list .mat-list-option,
.mat-selection-list .mat-list-option {
    color: rgba(0, 0, 0, 0.87);
}

.mat-list .mat-subheader,
.mat-nav-list .mat-subheader,
.mat-selection-list .mat-subheader {
    color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
    background-color: #eeeeee;
}

.mat-list-option:hover,
.mat-list-option.mat-list-item-focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item.mat-list-item-focus {
    background: rgba(0, 0, 0, 0.04);
}

.mat-menu-panel {
    background: white;
}

.mat-menu-item {
    background: transparent;
    color: rgba(0, 0, 0, 0.87);
}

.mat-menu-item[disabled] {
    color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon:not([color]),
.mat-menu-item-submenu-trigger::after {
    color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
    background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
    background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
    color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
    border-right: 2px solid rgba(0, 0, 0, 0.54);
    border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
    border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-paginator-first,
.mat-mdc-icon-button[disabled] .mat-paginator-last {
    border-color: rgba(0, 0, 0, 0.38);
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #d50000;
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
    stroke: #424242;
}

.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
    stroke: #f44336;
}

.mat-radio-outer-circle {
    border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-disabled .mat-radio-outer-circle {
    border-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-disabled .mat-radio-inner-circle {
    background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-disabled .mat-radio-label-content {
    color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: #d50000;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle {
    background-color: #d50000;
}

.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element {
    background-color: rgba(213, 0, 0, 0.26);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #424242;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #424242;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: rgba(66, 66, 66, 0.26);
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
    border-color: #f44336;
}

.mat-radio-button.mat-warn .mat-radio-inner-circle {
    background-color: #f44336;
}

.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element {
    background-color: rgba(244, 67, 54, 0.26);
}

.mat-select-panel,
.mat-select-panel-done-animating {
    background: white;
}

.mat-select-value {
    color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
    color: rgba(0, 0, 0, 0.42);
}

.ebegu .mat-select-disabled {
    .DisabledInput();

    .mat-select-trigger {
        cursor: not-allowed;
    }
}

.mat-mdc-select .mat-mdc-select-arrow {
    color: rgba(0, 0, 0, 0.54);

    svg {
        left: 0;
    }
}

.mat-pseudo-checkbox.mat-pseudo-checkbox-full {
    height: 16px;
    margin-right: 12px;
    width: 16px;
}

.mat-mdc-option.mdc-list-item {
    .ebeguFont-family();
    min-height: 3em;
    font-size: 1.6rem;
    letter-spacing: normal;
}

.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error-wrapper {
    padding: 0;
}

.mat-select-panel {
    border-radius: 0 !important;
    box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(0, 0, 0, 0.12);
}

mat-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: #d50000;
}

mat-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
    color: #424242;
}

mat-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
    color: #f44336;
}

.mat-form-field .mat-select.mat-select-invalid .mat-mdc-select-arrow {
    color: #f44336;
}

.mat-form-field .mat-select.mat-select-disabled .mat-mdc-select-arrow {
    color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
}

.mat-drawer.mat-drawer-push {
    background-color: white;
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #9e9e9e;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: rgba(158, 158, 158, 0.5);
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
    background-color: rgba(0, 0, 0, 0.06);
}

.mat-slide-toggle .mat-ripple-element {
    background-color: rgba(158, 158, 158, 0.12);
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled)
    .mat-slide-toggle-thumb {
    background-color: #f44336;
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled)
    .mat-slide-toggle-bar {
    background-color: rgba(244, 67, 54, 0.5);
}

.mat-slide-toggle.mat-primary:not(.mat-checked) .mat-ripple-element {
    background-color: rgba(0, 0, 0, 0.06);
}

.mat-slide-toggle.mat-primary .mat-ripple-element {
    background-color: rgba(244, 67, 54, 0.12);
}

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled)
    .mat-slide-toggle-thumb {
    background-color: #f44336;
}

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled)
    .mat-slide-toggle-bar {
    background-color: rgba(244, 67, 54, 0.5);
}

.mat-slide-toggle.mat-warn:not(.mat-checked) .mat-ripple-element {
    background-color: rgba(0, 0, 0, 0.06);
}

.mat-slide-toggle.mat-warn .mat-ripple-element {
    background-color: rgba(244, 67, 54, 0.12);
}

.mat-disabled .mat-slide-toggle-thumb {
    background-color: #bdbdbd;
}

.mat-disabled .mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.1);
}

.mat-slide-toggle-thumb {
    background-color: #fafafa;
}

.mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
    background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
    background-color: #d50000;
}

.mat-primary .mat-slider-thumb-label-text {
    color: white;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
    background-color: #424242;
}

.mat-accent .mat-slider-thumb-label-text {
    color: white;
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
    background-color: #f44336;
}

.mat-warn .mat-slider-thumb-label-text {
    color: white;
}

.mat-slider-focus-ring {
    background-color: rgba(66, 66, 66, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
    background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
    background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-disabled:hover .mat-slider-track-background {
    background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
    background-color: rgba(0, 0, 0, 0.12);
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
    background-color: rgba(0, 0, 0, 0.87);
}

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused
    .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused
    .mat-slider-thumb-label {
    background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover
    .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused
    .mat-slider-thumb {
    border-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-min-value:not(
        .mat-slider-thumb-label-showing
    ):hover.mat-slider-disabled
    .mat-slider-thumb,
.mat-slider-min-value:not(
        .mat-slider-thumb-label-showing
    ).cdk-focused.mat-slider-disabled
    .mat-slider-thumb {
    border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
    border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
    background-image: repeating-linear-gradient(
        to right,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7) 2px,
        transparent 0,
        transparent
    );
    background-image: -moz-repeating-linear-gradient(
        0.0001deg,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7) 2px,
        transparent 0,
        transparent
    );
}

.mat-slider-vertical .mat-slider-ticks {
    background-image: repeating-linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7) 2px,
        transparent 0,
        transparent
    );
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
    color: rgba(0, 0, 0, 0.38);
}

.mat-step-header .mat-step-icon {
    background-color: #d50000;
    color: white;
}

.mat-step-header .mat-step-icon-not-touched {
    background-color: rgba(0, 0, 0, 0.38);
    color: white;
}

.mat-step-header .mat-step-label.mat-step-label-active {
    color: rgba(0, 0, 0, 0.87);
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
    background-color: white;
}

.mat-stepper-vertical-line::before {
    border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-stepper-horizontal-line {
    border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-tab-nav-bar,
.mat-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
    border-bottom: none;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-label,
.mat-tab-link {
    color: rgba(0, 0, 0, 0.87);
}

.mat-tab-label.mat-tab-disabled,
.mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*='mat-background-'] .mat-tab-header,
.mat-tab-nav-bar[class*='mat-background-'] {
    border-bottom: none;
    border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus {
    background-color: rgba(198, 40, 40, 0.3);
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #d50000;
}

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
    background-color: white;
}

.mat-tab-group.mat-accent .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-accent .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-accent .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-accent .mat-tab-link:not(.mat-tab-disabled):focus {
    background-color: rgba(97, 97, 97, 0.3);
}

.mat-tab-group.mat-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent .mat-ink-bar {
    background-color: #424242;
}

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
    background-color: white;
}

.mat-tab-group.mat-warn .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-warn .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-warn .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-warn .mat-tab-link:not(.mat-tab-disabled):focus {
    background-color: rgba(255, 205, 210, 0.3);
}

.mat-tab-group.mat-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn .mat-ink-bar {
    background-color: #f44336;
}

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
    background-color: white;
}

.mat-tab-group.mat-background-primary
    .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-background-primary
    .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-primary
    .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-primary
    .mat-tab-link:not(.mat-tab-disabled):focus {
    background-color: rgba(198, 40, 40, 0.3);
}

.mat-tab-group.mat-background-primary .mat-tab-header,
.mat-tab-group.mat-background-primary .mat-tab-links,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links {
    background-color: #d50000;
}

.mat-tab-group.mat-background-primary .mat-tab-label,
.mat-tab-group.mat-background-primary .mat-tab-link,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link {
    color: white;
}

.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
    border-color: white;
}

.mat-tab-group.mat-background-primary
    .mat-tab-header-pagination-disabled
    .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary
    .mat-tab-header-pagination-disabled
    .mat-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-primary .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.12);
}

.mat-tab-group.mat-background-accent
    .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-background-accent .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-accent
    .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-accent
    .mat-tab-link:not(.mat-tab-disabled):focus {
    background-color: rgba(97, 97, 97, 0.3);
}

.mat-tab-group.mat-background-accent .mat-tab-header,
.mat-tab-group.mat-background-accent .mat-tab-links,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header,
.mat-tab-nav-bar.mat-background-accent .mat-tab-links {
    background-color: #424242;
}

.mat-tab-group.mat-background-accent .mat-tab-label,
.mat-tab-group.mat-background-accent .mat-tab-link,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link {
    color: white;
}

.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
    border-color: white;
}

.mat-tab-group.mat-background-accent
    .mat-tab-header-pagination-disabled
    .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent
    .mat-tab-header-pagination-disabled
    .mat-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-accent .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.12);
}

.mat-tab-group.mat-background-warn .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-background-warn .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-warn
    .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-warn
    .mat-tab-link:not(.mat-tab-disabled):focus {
    background-color: rgba(255, 205, 210, 0.3);
}

.mat-tab-group.mat-background-warn .mat-tab-header,
.mat-tab-group.mat-background-warn .mat-tab-links,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header,
.mat-tab-nav-bar.mat-background-warn .mat-tab-links {
    background-color: #f44336;
}

.mat-tab-group.mat-background-warn .mat-tab-label,
.mat-tab-group.mat-background-warn .mat-tab-link,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link {
    color: white;
}

.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
    border-color: white;
}

.mat-tab-group.mat-background-warn
    .mat-tab-header-pagination-disabled
    .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn
    .mat-tab-header-pagination-disabled
    .mat-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-warn .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
    background: whitesmoke;
    color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
    background: #d50000;
    color: white;
}

.mat-toolbar.mat-accent {
    background: #424242;
    color: white;
}

.mat-toolbar.mat-warn {
    background: #f44336;
    color: white;
}

.mat-tooltip {
    background: rgba(97, 97, 97, 0.9);
}

.mat-snack-bar-container {
    background: #323232;
    color: white;
}

.mat-simple-snackbar-action {
    color: #424242;
}

// Adaptions after Angular 15

.mat-primary
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text,
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: black !important;
}

.mat-accent
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: #424242;
}

.mat-warn
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: #f44336;
}

.mat-mdc-select-panel[aria-multiselectable='false'] .mat-pseudo-checkbox {
    display: none;
}

.mdc-checkbox__background {
    width: 16px !important;
    height: 16px !important;
}

.mat-mdc-checkbox,
.mat-pseudo-checkbox {
    width: 100%;
    --mdc-checkbox-state-layer-size: 20px;

    &.mat-accent,
    &.mat-mdc-option-pseudo-checkbox {
        --mdc-checkbox-selected-checkmark-color: #fff;
        --mat-standard-button-toggle-selected-state-text-color: #fff;
        --mdc-checkbox-selected-focus-icon-color: #424242;
        --mdc-checkbox-selected-hover-icon-color: #424242;
        --mdc-checkbox-selected-pressed-icon-color: #424242;
        --mdc-checkbox-unselected-focus-icon-color: #424242;
        --mdc-checkbox-unselected-hover-icon-color: #424242;
        --mdc-checkbox-selected-icon-color: #424242;
    }

    .mdc-form-field .mdc-checkbox {
        padding-left: 0.5rem;

        & + label {
            color: #000;
            font-family:
                Open Sans,
                Arial,
                Helvetica,
                sans-serif;
            font-style: normal;
            font-weight: 600;
            line-height: 21px;
        }

        .mdc-checkbox__ripple {
            display: none;
        }
    }
}

.onboarding-container .mat-mdc-checkbox .mdc-form-field .mdc-checkbox + label {
    font-family:
        Open Sans,
        sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
}

.mat-mdc-radio-group .mat-mdc-radio-button,
.mat-pseudo-checkbox {
    &.mat-accent {
        --mdc-checkbox-selected-checkmark-color: #fff;
        --mdc-radio-disabled-unselected-icon-color: #000;
        --mdc-radio-unselected-focus-icon-color: #212121;
        --mdc-radio-unselected-hover-icon-color: #212121;
        --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
        --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
        --mdc-radio-selected-focus-icon-color: #424242;
        --mdc-radio-selected-hover-icon-color: #424242;
        --mdc-radio-selected-icon-color: #424242;
        --mdc-radio-selected-pressed-icon-color: #424242;
        --mat-mdc-radio-ripple-color: #000;
        --mat-mdc-radio-checked-ripple-color: #424242;
        --mat-radio-checked-ripple-color: #424242;
    }

    .mdc-form-field .mdc-radio {
        padding: 3px;
        width: 20px;

        & + label {
            color: #000;
            cursor: pointer;
            font-style: normal;
            font-weight: 600;
            line-height: 21px;
        }

        .mat-radio-ripple {
            display: none;
        }
    }
}

.mdc-data-table__header-cell {
    color: rgba(0, 0, 0, 0.54);
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 12px;
    line-height: 17px;
}

.mdc-data-table__cell {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.mdc-text-field--disabled.mdc-text-field--filled {
    background-color: transparent;
}

:root {
    --mdc-typography-body1-font-family: Open Sans, Arial, Helvetica, sans-serif;
    --mdc-typography-body1-font-size: 10.5px;
    --mdc-typography-body1-font-weight: 500;
    --mdc-typography-body2-font-size: 10.5px;
    --mdc-typography-font-family: Open Sans, Arial, Helvetica, sans-serif;
}

.mat-mdc-menu-trigger.user-menu-trigger.mdc-button.mat-mdc-button.mat-unthemed.mat-mdc-button-base {
    height: 100%;
}

.mat-mdc-select {
    padding: 1rem 0;
    --mat-select-trigger-text-font: Open Sans, Arial, Helvetica, sans-serif;
    --mat-select-trigger-text-weight: 700;
    --mat-select-trigger-text-size: 1.6rem;

    .mat-mdc-select-value-text {
        padding-left: 1rem;
    }
}

.mdc-icon-button.mat-mdc-icon-button {
    padding: 3px;
}

.mat-mdc-paginator-container .mat-mdc-paginator-page-size {
    margin-right: 0;

    .mat-mdc-text-field-wrapper.mdc-text-field {
        padding: 0 10%;
        width: 80%;
    }

    .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        padding: 0;
    }
}

.mat-mdc-select.mat-mdc-select-disabled {
    background-color: @contrast;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
    background: #424242 !important;
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-select-panel {
    min-width: max-content;
}

.mat-mdc-table .mat-mdc-row label {
    color: black;
}

.mat-mdc-radio-button,
.mat-mdc-button {
    --mdc-form-field-label-text-font: 'Open Sans', Arial, Helvetica, sans-serif;
    --mat-radio-label-text-font: 'Open Sans', Arial, Helvetica, sans-serif;
}
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    width: 11px !important;
    height: 3px !important;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    background-color: @ddgrey !important;
}

* {
    --mdc-filled-text-field-disabled-container-color: transparent;
}

/* stylelint-enable */
