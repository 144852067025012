/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../variables.less';
@import (reference) '../mixins.less';

.dv-btn {
    .ebeguNormal();
    background: @primary;
    border: none;
    color: @contrast-lightest;
    font-size: 1.4rem;
    height: 4rem;
    letter-spacing: 0.2rem;
    outline: 2px solid transparent;
    outline-offset: 0;
    padding: 0 1.5rem 0;
    text-align: center;
    white-space: nowrap;
    span {
        line-height: inherit;
        text-align: center;
        vertical-align: middle;
    }
    &.active,
    &:active {
        background: @primary;
        color: @contrast-lightest;
        text-decoration: none !important;
    }
    &:hover {
        background: @primary-dark;
        color: @contrast-lightest;
    }

    &:active,
    &:hover {
        outline: none;
        text-decoration: none !important;
    }
    &:focus {
        outline: 2px solid @contrast-darkest;
        outline-offset: 0;
    }
    &[disabled],
    &[disabled]:hover {
        background: @contrast;
        color: @contrast-lightest;
    }
}

.color-success {
    background: @success;
    &:hover {
        background: @success-dark;
    }
}

.color-warning {
    background: @warning;
    &:hover {
        background: @warning-dark;
    }
}

.color-danger {
    background: @danger;
    &:hover {
        background: @danger-dark;
    }
}

.dv-btn-mutation-hinzufuegen {
    margin-top: 2rem;
}

md-dialog-actions {
    padding: 0 2rem 0;
    button {
        margin: 0.8rem;
        min-width: 10rem;
        width: auto;
        span {
            font-size: 1.6rem;
        }
    }
}

dv-navigation {
    button {
        // margin:2rem 0 -1rem;
    }
}

.btn-link {
    background: transparent;
    color: @contrast-darkest;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    &:hover,
    &:active {
        background: transparent;
        color: @contrast-darkest;
    }
    &.link-underline {
        padding: 0;
        &:hover {
            color: @contrast-darkest;
        }

        i {
            text-decoration: none;
        }
        span {
            text-decoration: underline;
        }
    }
    span {
        line-height: normal;
    }
    &[disabled],
    &[disabled]:hover {
        background: none;
        box-shadow: none;
        outline: 0;
        &:focus {
            outline: 2px solid @contrast-darkest;
        }
    }
    &.link-color-primary {
        color: @primary;
    }
}

.dv-btn-sm {
    border-radius: 0;
    height: 3rem;
    letter-spacing: normal;
    padding-left: 1rem;
    padding-right: 1rem;
    span {
        line-height: 3rem;
    }
}

.dv-btn-bg {
    font-size: 2.4rem;
    height: 5rem;
}

.dv-btn-institution {
    margin-right: 4rem;
}

.dv-btn-operation {
    background: transparent;
    color: @contrast-darkest;
    letter-spacing: 0.1rem;
    padding: 0;
    &.centered-btn {
        width: 100%;
    }
    .fa {
        vertical-align: middle;
    }
    &.active,
    &:active {
        background: transparent;
        color: @contrast-darkest;
        .fa {
            color: @primary;
        }
    }
    &:hover,
    &:focus {
        background: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: @contrast-darkest;
        outline: 2px solid @contrast-darkest;
        .fa {
            color: @primary;
            outline: 0;
        }
    }

    &:active,
    &:hover {
        outline: none;
        text-decoration: none !important;
    }

    &[disabled],
    &[disabled]:hover {
        background: transparent;
        color: @contrast-dark;
        .fa {
            color: @contrast-dark;
        }
    }
}

a.dv-btn {
    display: block;
    text-decoration: none;
    span {
        line-height: 4rem;
        text-align: center;
        text-decoration: none;
    }
}

.cancel-button {
    background: @secondary-button;
    color: @black;
    &:hover,
    &:active {
        background: @secondary-button-hover;
        color: @black;
    }
}

.dv-btn-operation--white {
    color: @contrast-lightest;
    &.active,
    &:active {
        background: transparent;
        color: @contrast-lightest;
        .fa {
            color: @primary;
        }
    }
}
