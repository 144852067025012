/*
 * Copyright (C) 2023 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

@import (reference) './../../../../style/mixins.less';
@import (reference) './../../../../style/variables.less';

dv-input-container-x {
    .dv-input-container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &.vertical-align {
            flex-direction: column;
            margin-bottom: 4rem;
            > label,
            dv-radio-container-x,
            mat-form-field,
            .flex-whitespace-item {
                flex-grow: 1;
                margin: 0 2rem;
                width: 100%;
            }
        }
        &:not(.vertical-align) {
            &:not(.no-margin) {
                margin-bottom: 3rem;
            }
            dv-radio-container-x,
            mat-form-field,
            .flex-whitespace-item {
                //space for outline
                flex: 0 0 180px;
                margin-left: 6rem;
            }
        }

        input,
        textarea {
            font-family:
                Open Sans,
                Arial,
                Helvetica,
                sans-serif;
        }

        label {
            color: black;
            flex: 1 0 35%;
            font-family:
                Open Sans,
                Arial,
                Helvetica,
                sans-serif;
            &.bold-label {
                font-weight: bold;
            }
        }

        label[required]:after {
            color: black;
            content: '*';
            //add negative margin to compensate width and
            //prevent asterisk from wrapping to next line
            margin-right: -10px;
            padding-left: 0.3rem;
        }

        textarea {
            padding: 1rem;
        }

        .mat-mdc-select-trigger {
            height: 100%;

            .mat-mdc-select-value-text {
                padding-left: 0;
            }
        }
    }

    mat-label {
        .ebeguLabel();
        color: @contrast-darkest;
        display: block;
        font-family:
            Open Sans,
            Arial,
            Helvetica,
            sans-serif;
        &:after {
            .ebeguLabel();
            color: @contrast-darkest;
            display: inline-block;
        }
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-mdc-text-field-wrapper {
        padding-bottom: 0;
    }

    .mat-form-field-infix {
        border-top: 0;
    }

    .mat-form-field-subscript-wrapper {
        top: calc(100% - 0.9em);
    }

    .mdc-text-field--no-label:not(.mdc-text-field--textarea)
        .mat-mdc-form-field-input-control.mdc-text-field__input,
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
        height: 4rem;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
            .mdc-text-field--textarea
        )
        .mat-mdc-form-field-infix {
        padding: 0;
    }

    .mat-mdc-form-field-infix {
        border: 2px solid transparent;
        min-height: 0;
    }

    .mdc-text-field {
        padding-right: 0;
        padding-left: 0;
        border-radius: 0;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: inherit;
    }

    .mdc-line-ripple::before {
        border-bottom-width: 0;
    }

    [matformfieldlineripple] {
        display: none;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--textarea)
        .mat-mdc-form-field-input-control.mdc-text-field__input,
    dv-input-container-x
        .mat-mdc-text-field-wrapper
        .mat-mdc-form-field-input-control {
        font-size: 1.6rem;
        .ebeguFont-family();
        .ebeguSemi-bold();
    }

    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
        letter-spacing: inherit;
        text-decoration: inherit;
        text-transform: inherit;
        border: none;
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
    }
}

.dv-input-container {
    width: 100%;

    &.disabled {
        label,
        mat-label > .mat-label-content {
            color: @dgrey;
        }
        label[required]:after {
            color: @dgrey;
        }
    }
}

.gesuch dv-input-container-x {
    .dv-input-container {
        &.vertical-align {
            align-items: start;
        }
    }
    input.ng-invalid,
    select.ng-invalid,
    mat-radio-group.ng-invalid {
        background-color: @background-error-color;
    }

    .dv-select-style {
        width: 100%;
    }

    mat-form-field,
    dv-radio-container-x {
        margin-left: 0 !important;
    }

    .no-margin mat-form-field {
        .mat-mdc-text-field-wrapper {
            padding-bottom: 0 !important;
        }
    }

    mat-form-field {
        .mat-mdc-select-arrow-wrapper {
            & > .mat-mdc-select-arrow {
                display: none;
            }

            &::after {
                color: rgba(0, 0, 0, 0.87);
                content: ' \F107';
                font-family: FontAwesome, sans-serif;
                font-size: 1.6rem;
                font-weight: 300;
                pointer-events: none;
                position: relative;
                right: 1rem;
                z-index: 2;
            }
        }
    }

    mat-select {
        background-color: @contrast-lightest;

        .mat-mdc-select-value {
            line-height: 2rem;
            height: 4rem;

            .mat-mdc-select-value-text {
                font-weight: 700;
                padding: 0 24px 0 1rem;
            }
        }
    }
}
