/*
 * Copyright (C) 2019 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
@import (reference) '../../../style/variables.less';
@import (reference) '../../../style/mixins.less';

.abweichungen-view {
    .table input[type='number'] {
        background: @contrast-lightest;

        &[disabled],
        &:disabled {
            .DisabledInput();
            .ebeguDisabledPlaceholder();
            -webkit-text-fill-color: @contrast-darkest;
        }
    }

    .table tr:hover,
    .table tr:first-child:hover {
        background-color: transparent;
    }
}

.green {
    color: @success;
}
.orange {
    color: @warning;
}
.black {
    color: @contrast-darkest;
}
