/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import '../../../../style/variables.less';
@import '../../../../style/mixins.less';

@buttonWidth: 24rem;
@buttonHeight: 15rem;

.gesuchsteller-dashboard-view {
    background: url('../../../../assets/images/kitax_bg.jpg') no-repeat center
        center fixed; /* fallback */
    background:
        linear-gradient(rgba(40, 40, 40, 0.85), rgba(40, 40, 40, 0.85)),
        url('../../../../assets/images/kitax_bg.jpg') no-repeat center center
            fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: @contrast-lightest;
    overflow-y: auto;

    .ebegu-h3,
    h2 {
        color: @contrast-lightest;
    }

    .dashboard-header {
        margin-top: 6rem;
        z-index: @z-index-dashboard;
        & > * {
            margin: 2rem;
        }
    }

    .dashboard-error {
        font-family: 'Open Sans', Arial, Helvetica, sans-serif;
        font-size: 3rem;
        width: 100rem;
    }
    .gdashboard-panel {
        display: flex;
        flex-direction: column;
        margin: 3rem;
        width: 75%;
        &-gesuchstring {
            .ebeguBold();
            color: @contrast-light;
            font-family: 'Open Sans', Arial, Helvetica, sans-serif;
            font-size: 2.8rem;
            letter-spacing: 0.15rem;
            margin: 0;
        }
        &-gesuchrange {
            .ebeguSemi-bold();
            font-family: 'Open Sans', Arial, Helvetica, sans-serif;
            font-size: 1.6rem;
            letter-spacing: 0.1rem;
            margin-bottom: 2rem;
            margin-top: 0.5rem;
        }
        &-button {
            margin-bottom: 1rem;
            text-transform: uppercase;
            vertical-align: bottom;
            width: 100%;
        }
        &-seperator {
            height: 0;
        }

        @media screen and (min-width: 600px) {
            &:first-of-type {
                margin-left: auto;
            }
            &:last-of-type {
                margin-right: auto;
            }
        }
    }

    .mobile-center-center {
        align-content: center;
        align-items: center;
        -ms-flex-align: center;
        -ms-flex-line-pack: center;
        -ms-flex-pack: center;
        max-width: 100vw;
        @media screen and (min-width: 600px) {
            justify-content: flex-start;
        }
    }

    .dv-btn {
        border: 1px solid @primary-light;
        text-wrap: pretty;
    }
}
