/*
 * Copyright (C) 2018 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

@import (reference) '../../../style/variables.less';

md-input-container:not(.md-input-has-value) input:not(:focus),
.dv-betreuung-input {
    color: @contrast-darkest !important;
    width: 100%;
}

.betreuung-input-container {
    background: @contrast-lightest;
}

.dv-betreuung-input-info-label {
    line-height: 4rem;
    overflow: hidden;
    padding-left: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pensum-input {
    .dv-input-container-medium-label {
        float: left;
        width: 30rem;
        @media only screen and (max-width: 600px) {
            width: 20rem;
        }
    }
    .betreuung-input-switch {
        float: left;
        width: auto;
        @media only screen and (min-width: 600px) {
            margin-left: -32px;
        }
    }
    .dv-betreuung-input-info-label {
        float: left;
    }
    .col-md-12 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
