/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../../../style/variables.less';

md-checkbox.checkbox-error {
    background: #ffe6e6;
}

.dv-tagesschule-table {
    &-checkbox {
        height: 1.7rem;
        margin: auto;
        width: 1.7rem;
        md-checkbox {
            margin: 0 !important;
        }
    }
    &-weekdays {
        padding: 0 1rem;
        text-align: center;
    }
    &-tr {
        td {
            padding: 1rem 0;
        }
    }
}

.dv-input-container-big {
    width: 80rem;
}

@media (max-width: 667px) {
    .dv-ebegu-calendar-day {
        .md-label {
            position: relative !important;

            span {
                font-size: 0.8rem !important;
            }
        }
    }

    .dv-ebegu-calendar--tagesschule .md-label span {
        display: inline !important;
    }
}
