/*
 * Copyright (C) 2023 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */
@import (reference) 'variables.less';

@media @mq-xs {
    .gesuch-modul {
        &.betreuung-view {
            padding: 5rem 3rem 10rem;
            .well {
                margin-left: -3rem;
                margin-right: -3rem;
            }
        }
    }

    md-dialog.md-default-theme.md-dialog-fullscreen.dv-tooltip-dialog,
    md-dialog {
        font-size: 1.8rem;
        .md-dialog-content {
            padding: 5rem;
        }
    }

    .gesuchsteller-dashboard-view {
        .dashboard-header {
            align-items: stretch;
            margin-top: 20rem !important;
        }
        .gdashboard-panel + .gdashboard-panel {
            padding-left: 6rem;
        }
        .gdashboard-panel {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0;
            max-width: 38rem;
            text-align: center;
            &:not(:last-of-type) {
                border-right: 1px solid @contrast;
                padding-right: 6rem;
                &:not(:first-of-type) {
                    max-width: 38rem;
                    padding-left: 6rem;
                }
            }
            .gdashboard-panel-button {
                &--margin {
                    margin-bottom: 5rem;
                    margin-top: 5rem;
                }
                &--ansehen {
                    margin-bottom: -5rem;
                }
                &--margin + .gdashboard-panel-text {
                    margin-top: -5rem;
                }
                &--ansehen + .gdashboard-panel-text {
                    margin-top: 5rem;
                }
            }
        }
    }

    .dv-progressbar,
    .mat-progress-bar {
        top: 9.5rem !important;
    }

    .dv-input-container-small {
        width: 30rem;
    }

    .dv-input-betreuung-container {
        width: 70rem;
    }

    .dv-input-container-medium-label {
        padding-right: 32px;
        width: 30rem;
    }

    .dv-input-container-medium-select {
        width: 26rem;
    }

    .dv-input-container-medium {
        width: 62rem;
    }

    .dv-input-container-question {
        max-width: 100%;
        min-width: 30rem;
        width: auto;
    }

    /*  .gesuch-modul {
          padding-bottom: 10rem;
      }*/
    #sidenavContainer {
        max-width: 35.5rem;
        min-width: 35.5rem;
        right: unset;
        width: 35.5rem;
        li {
            width: 35.5rem;
            &:before {
                width: 30rem; /* or 100px */
            }
        }
    }

    #gesuchContainer {
        .dv-navigation--verfuegen-list {
            flex-direction: row-reverse;
        }
    }
}

@media @mq-sm {
    .gesuch-modul {
        padding: 5rem 2.5rem 10rem 5rem;
        &.betreuung-view {
            padding: 5rem 2.5rem 10rem 5rem;

            .well {
                margin-left: -5rem;
                margin-right: -2.5rem;
            }
        }
    }

    .mitteilungen-view {
        padding: 6rem;
        dv-accordion {
            dv-accordion-tab {
                width: 95%;
                .dv-accordion-tab-title {
                    & .arrow {
                        width: 4rem;
                    }
                }
            }
        }
    }

    #gesuchContainer {
        .dv-navigation,
        .dv-navigation-flex {
            justify-content: center;

            .dv-navigation-item {
                padding: 0.75rem;
            }
        }
    }

    .onboarding-gemeinde-container {
        justify-content: space-between;
    }

    .onboarding-gemeinde-input-container {
        width: 100%;
    }
}

//Device Media Queries
@media @mq-d-xs {
    html {
        font-size: @base-font-size*0.75;
    }
}

@media @mq-d-xxlg {
    html {
        font-size: @base-font-size;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
    only screen and (max-device-width: 640px),
    only screen and (max-device-width: 667px),
    only screen and (max-width: 480px) {
    h2 {
        line-height: 2.4rem;
    }

    .dv-input-container-small {
        width: 100%;
    }

    .dv-input-container-calendar {
        width: 100%;
    }

    .dv-input-container-medium-label {
        width: 100%;
    }

    .dv-input-container-medium {
        width: 100%;
    }

    .dv-input-container-question {
        margin-bottom: 4rem;
        width: 100%;
    }

    .gesuch-modul {
        padding: 5rem 3rem 13rem;
    }
    .dv-error-messages {
        height: auto;
        line-height: normal;
        min-height: 3rem;
    }
    dv-eingabe-hint {
        display: inline-block;
        height: 2.5rem;
        margin: 0;
        margin-top: -2.5rem;
        position: relative;
    }

    html {
        font-size: @base-font-size;
    }

    .dv-ebegu-calendar {
        &-module {
            padding: 1rem;
        }
        &-weekday {
            min-width: 0;
            //  font-size: 1rem;
            overflow: hidden;
            padding: 0;
            text-overflow: ellipsis;
        }
        &-container-weekdays {
            flex-wrap: nowrap;
        }
        &-date {
            &--day {
                font-size: 1.3rem !important;
                margin-top: auto !important;
            }
            &--year {
                font-size: 0.9rem !important;
                margin-bottom: auto !important;
            }
        }
    }
}
