/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../../../../style/variables.less';
@import (reference) '../../../../style/mixins.less';

.bisher-form {
    display: inline-block;
    width: 100%;
}

.dv-bisher {
    background-color: @contrast;
    border-color: @contrast-light;
    clear: both;
    display: inline-block;
    font-size: 1.6rem;
    height: auto;
    margin: 1rem 0 0;
    min-height: 2.3rem;
    padding: 0 0 0 1rem;
    width: 100%;
    span:first-child {
        .ebeguItalic();
    }
    span {
        color: @contrast-darkest;
        font-size: 1.3rem;
        letter-spacing: 0.1rem;
        line-height: 1rem;
    }
}

.dv-bisher-break span {
    display: block;
    height: auto;
    line-height: 2.3rem;
    min-height: 2.3rem;
}
