/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../variables.less';
@import (reference) '../mixins.less';

md-radio-group.radio,
.radio {
    margin: 0;
    &:not(.ng-not-empty):focus {
        md-radio-button {
            &:first-child {
                .md-off {
                    background: rgba(0, 0, 0, 0.4);
                    box-shadow: 0 0 0 0.3rem rgba(0, 0, 0, 0.4);
                }
            }
        }
    }
    &.ng-not-empty:focus {
        md-radio-button {
            &.md-checked {
                .md-on {
                    box-shadow: 0 0 0 3.5rem rgba(0, 0, 0, 0.4);
                }
            }
        }
    }
}

md-radio-button {
    color: @contrast-darkest;
    height: auto;
    margin: 0 0 1.5rem;
    min-height: 1.5rem;
    &:last-child {
        margin-bottom: 0;
    }
    .md-container {
        display: block !important;
        height: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        width: 1.5rem;
        &:before {
            content: none;
        }
    }
    .md-label {
        display: block;
        height: auto;
        // font-size:1.4rem;
        line-height: 1.5rem;
        margin-left: 3rem;
        min-height: 1.5rem;
        span {
            .ebeguH3();
            height: 1.5rem;
            // font-size:1.4rem;
            line-height: 1.5rem;
        }
    }
    .md-off {
        border-color: @contrast-darkest;
        border-width: 1px;
        height: 1.5rem;

        width: 1.5rem;
    }

    .md-on {
        background-color: @contrast-darkest;
        border: none;
        height: 1.5rem;
        width: 1.5rem;
    }
    &.md-checked {
        .md-off {
            border-color: @contrast-darkest;
            height: 1.5rem;

            width: 1.5rem;
        }
        .md-on {
            background-color: @contrast-darkest;
            border: none;
            border-color: @contrast-darkest;
            height: 1.5rem;
            transform: scale(0.3);
            width: 1.5rem;
        }
    }
    &[disabled] {
        color: @contrast-dark;
        .md-container {
            .md-off {
                border-color: @contrast-dark;
            }
        }

        .md-on {
            background-color: @contrast-dark;
            border: none;
            border-color: @contrast-dark;
        }
        &.md-checked {
            .md-off {
                border-color: @contrast-dark;
            }
        }
    }
}
