/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../../../../style/variables.less';
@import (reference) '../../../../style/mixins.less';

dv-tooltip {
    font-size: 1.6rem;
    i:focus {
        color: @primary;
        outline: 2px solid @contrast-darkest;
    }
    &:hover {
        i {
            color: @primary;
        }
    }
}

md-dialog.md-dialog-fullscreen.dv-tooltip-dialog {
    background: @contrast-light;
    display: flex;
    font-size: 1.6rem;
    // make it work in IE11 too
    height: 100vh;
    min-height: 100%;
    min-width: 100%;
    .tooltip-close-button {
        display: flex;
    }
    md-dialog-content {
        flex: 1;
        margin-top: 2.5rem;
        padding: 2.5rem;
        padding-top: 0;
    }
    md-dialog-actions {
        padding: 2rem;
    }
    a {
        font-size: 1.6rem;
    }
    li {
        -webkit-margin-after: 1rem;
        -webkit-margin-before: 1rem;
        -webkit-margin-end: 0;
        -webkit-margin-start: 0;
        text-align: -webkit-match-parent;
    }
}
