/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2018 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../variables.less';
@import (reference) '../mixins.less';

.dv-ebegu-calendar {
    &-wrapper-small {
        min-height: 1px;
        width: 100%;
    }
    &-wrapper-small-blank {
        display: none;
        min-height: 1px;
        width: 0;
    }
    &-wrapper {
        min-height: 1px;
        width: 100%;
    }
    &-wrapper-tagesschule {
        background: @contrast;
        min-height: 1px;
        width: 100%;
    }

    &-date {
        &--day {
            font-size: 2.4rem !important;
            font-weight: bold !important;
            margin: auto 0 !important;
        }
        &--year {
            font-size: 1.3rem !important;
            font-weight: normal !important;
            margin-bottom: 1.5rem !important;
        }
    }
    &-row--tagesschule {
        align-items: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &-break {
        flex-basis: 100%;
        height: 0;
        overflow: hidden;
        width: 0;
    }

    &-container,
    &-container-weekdays {
        background: @contrast;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &--no-margin {
            margin: 0;
        }
    }
    &-weekday {
        .ebeguH3();
        background: @contrast-lighter;
        border: 1px solid @contrast;
        border-bottom-width: 2px;
        border-top-width: 2px;
        color: @contrast-darkest;
        flex: 0 1 auto;
        margin: 0;
        padding: 1rem 0;
        text-align: center;
        width: 20%;
        &--Monday,
        &--MONDAY {
            border-left-width: 2px;
        }
        &--Friday,
        &--FRIDAY {
            border-right-width: 2px;
        }
    }
    &-module {
        align-items: flex-start;
        background: @contrast-lighter;
        border: 2px solid @contrast;
        border-top-width: 0;
        display: flex;
        flex: 1;
        flex-basis: 100%;
        flex-direction: row;
        flex-shrink: 0;
        justify-content: center;
        padding: 2rem;
        &-name,
        &-time {
            font-size: 1.4rem;
            line-height: 1.4;
        }
        &-name {
            font-weight: bold;
            margin-right: 1rem;
        }
    }
    &-row--week {
        background: @contrast-light;
        border-bottom: 2px solid @contrast;
        border-top: 0;
        flex: 0 1 100%;
    }
    &-day {
        border: 1px solid @contrast;
        border-bottom-width: 2px;
        border-top-width: 0;
        color: @contrast-darkest;
        flex: 0 1 auto;
        position: relative !important;
        width: 20%;
        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
        &--1 {
            border-left-width: 2px;
        }
        &--2 {
            margin-left: 20%;
        }

        &--3 {
            margin-left: 40%;
        }

        &--4 {
            margin-left: 60%;
        }

        &--5 {
            border-right-width: 2px;
            margin-left: 80%;
        }

        &--1 + &--3,
        &--2 + &--4,
        &--3 + &--5 {
            margin-left: 20%;
        }
        &--1 + &--4,
        &--2 + &--5 {
            margin-left: 40%;
        }
        &--1 + &--2,
        &--2 + &--3,
        &--3 + &--4,
        &--4 + &--5 {
            margin-left: 0;
        }
    }
}

@media @mq-xs {
    .dv-ebegu-calendar {
        &-wrapper-small,
        &-wrapper-small-blank {
            display: block;
            min-height: 1px;
            width: 20rem;
        }
        &-wrapper {
            min-height: 1px;
            width: 60rem;
        }
        &-wrapper-tagesschule {
            background: @contrast;
            min-height: 1px;
            width: 80rem;
        }
        &-module {
            align-items: flex-start;
            background: @contrast-lighter;
            border: 2px solid @contrast;
            border-right-width: 0;
            border-top-width: 0;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            padding: 2rem;
            &-name,
            &-time {
                font-size: 1.4rem;
                line-height: 1.4;
            }
            &-name {
                font-weight: bold;
            }
        }
    }
}
