/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import '../../../../style/variables.less';
@import '../../../../style/mixins.less';
@import '../../../../style/atoms/input.less';

@buttonWidth: 24rem;
@buttonHeight: 15rem;

.create-angebot-view {
    padding-left: 2.5rem !important;
    &--centered-col {
        float: unset;
        margin: auto;
    }
    .nav-row-betreuung {
        align-items: center;
        bottom: -10rem;
        -ms-box-orient: horizontal;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        left: 0;
        margin: 0;
        position: absolute;
        transform: unset;
        & > div {
            clear: both;
            display: flex;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
            margin-bottom: 0;
            padding: 0 3rem 0;
            position: static;
            width: 100%;
        }
    }
}
