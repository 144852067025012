/*
 * Copyright (C) 2023 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */
@import (reference) '../variables.less';
@import (reference) '../mixins.less';

.checkbox {
    font-size: 0;
    height: auto;
    &:not(.no-margin-bottom) {
        margin: 0 0 3rem;
    }
    &:not(:has(dv-tooltip)) {
        min-height: 1.7rem;
    }
    dv-tooltip {
        display: inline-block;
        font-size: 0;
        height: 1.7rem;
        line-height: 1.7rem;
        margin-left: -4rem;
        vertical-align: middle;
        i {
            font-size: 1.6rem;
        }
    }
    .dv-error-messages {
        display: block;
        height: auto;
        margin-bottom: -3rem;
        min-height: 3rem;
        overflow: visible;
    }
}

md-checkbox {
    float: left;
    font-size: 0;
    height: auto;
    margin-bottom: 0;
    margin-right: 4rem;
    margin-top: 0;
    min-height: 1.7rem;
    min-width: 0;
    position: relative;
    width: auto;
    &:last-of-type {
        margin-right: 4rem;
    }
    &[disabled] {
        color: @contrast-dark;
        .md-label {
            span {
                color: @contrast-dark;
            }
        }
        &.md-checked,
        &:not(.md-checked) {
            .md-icon {
                border: 1px solid @contrast-dark;
            }
        }
        &.md-checked {
            .md-icon {
                background: @contrast-dark;
            }
        }
    }
    &.md-focused,
    &:focus {
        .md-icon {
            box-shadow: 0 0 0 2px @contrast-darkest;
            //-webkit-box-shadow: 0 0 4px 0 #858585;
            //box-shadow: 0 0 4px 0 #858585;
            //outline: 2px solid @contrast-darkest;
            transition: none;
        }
    }
    .md-container {
        display: block;
        height: 1.7rem;
        width: 1.7rem;
        &:before {
            content: none !important;
        }
    }
    .md-label {
        display: inline-block;
        font-size: 0;
        height: auto;
        line-height: 1.7rem;
        margin-left: 3rem;
        min-height: 1.7rem;
        vertical-align: middle;

        span {
            .ebeguH3();
            display: block;
            height: auto;
            line-height: 1.7rem;
            margin: 0;
            margin-right: 1rem;
            min-height: 1.7rem;
            vertical-align: middle;
        }
    }
    .md-icon,
    &:not(.md-checked) .md-icon {
        border: 1px solid @contrast-darkest;
        border-radius: 0.2rem;
        bottom: 0;
        height: auto;
        left: 0;
        line-height: 1.7rem;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
    &.md-checked {
        .md-icon {
            background-color: @contrast-darkest;
            &:after {
                border-radius: 0.2rem;
                border-width: 0;
                bottom: 0;
                color: @contrast-lightest;
                content: '\f00c';
                display: block !important;
                font-family: 'FontAwesome', sans-serif;
                font-size: 0.8rem;
                height: auto;
                left: 0;
                line-height: 175%;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                transform: none;
                vertical-align: middle;
                width: auto;
            }
        }
    }
}

md-checkbox.dv-ebegu-calendar {
    align-items: stretch;
    bottom: 0;
    display: flex;
    float: left;
    font-size: 0;
    height: 100%;
    justify-content: center;
    left: 0;
    margin: 0;
    position: absolute !important;
    right: 0;
    top: 0;
    width: 100%;
    &:last-of-type {
        margin-right: 0;
    }
    &[disabled],
    &:disabled {
        color: @contrast-lightest;
        .md-label {
            span {
                color: @contrast-lightest;
            }
        }
        &.md-checked,
        &:not(.md-checked) {
            .md-icon {
                border: none !important;
            }
        }
        .md-icon {
            background: @contrast-dark;
        }
    }
    &.md-focused,
    &:focus {
        .md-icon {
            box-shadow: 0 0 0 2px @contrast-darkest;
            transition: none;
        }
    }
    .md-container {
        background: @contrast-lightest;
        display: block;
        height: 100%;
        width: 100%;
        &:before {
            content: none !important;
        }
    }
    .md-label {
        align-items: center !important;
        display: flex !important;
        flex: 1 0 auto !important;
        flex-direction: column;
        font-size: 0;
        height: unset !important;
        justify-content: flex-start !important;
        line-height: 1.7rem;
        margin-left: 0;
        max-height: none;
        min-height: 1.7rem;
        position: relative !important;
        text-align: center !important;
        vertical-align: top !important;

        span {
            border-bottom: none;
            display: initial !important;
            height: auto;
            line-height: initial !important;
            margin: 0;
            min-height: initial !important;
            padding: 0;
            vertical-align: initial !important;
        }
    }
    .md-icon,
    &:not(.md-checked) .md-icon {
        border: none;
        border-radius: 0;
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        line-height: 1.7rem;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
    &.md-checked {
        .md-label {
            color: @contrast-lightest;
            span {
                border-bottom-color: @contrast-lightest;
            }
        }
        .md-icon {
            background-color: @contrast-dark;
            top: 0;
            &:after {
                border-radius: 0;
                border-width: 0;
                bottom: unset !important;
                color: @contrast-lightest;
                content: '';
                display: none !important;
                font-family: 'FontAwesome', sans-serif;
                font-size: 2rem;
                height: auto;
                left: 0;
                line-height: normal;
                position: absolute;
                right: 0;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                vertical-align: middle;
                width: auto;
            }
        }
    }
}

md-checkbox.dv-ebegu-calendar--tagesschule {
    bottom: 0;
    display: block;
    float: left;
    font-size: 0;
    height: 100%;
    left: 0;
    margin: 0;
    position: absolute !important;
    right: 0;
    top: 0;
    width: 100%;
    &:last-of-type {
        margin-right: 0;
    }
    &[disabled],
    &:disabled {
        color: @contrast-lightest;
        .md-label {
            span {
                color: @contrast-lightest;
            }
        }

        &:not(.md-checked) .md-icon {
            background: @contrast-light;
            &:after {
                color: @contrast-light;
            }
        }

        &.md-checked,
        &:not(.md-checked) {
            .md-icon {
                border: none !important;
            }
        }
        .md-icon {
            background: @contrast-light;
        }
    }
    &.md-focused,
    &:focus {
        .md-icon {
            box-shadow: 0 0 0 2px @contrast-darkest;
            //-webkit-box-shadow: 0 0 4px 0 #858585;
            //box-shadow: 0 0 4px 0 #858585;
            //outline: 2px solid @contrast-darkest;
            transition: none;
        }
    }
    .md-container {
        background: @contrast-lightest;
        display: block;
        height: 100%;
        width: 100%;
        &:before {
            content: none !important;
        }
    }
    .md-label {
        display: block;
        font-size: 0;
        height: auto;
        line-height: 1.7rem;
        margin-left: 0;
        min-height: 1.7rem;
        position: relative !important;
        text-align: center;
        vertical-align: top;

        span {
            .ebeguH3();
            border-bottom: 1px solid @contrast;
            border-bottom: none;
            display: block;
            height: auto;
            line-height: 1.7rem;
            margin: 0;
            min-height: 1.7rem;
            padding: 8rem 0;
            vertical-align: middle;
            &.error {
                color: red;
                font-weight: bold;
            }
        }
    }
    .md-icon,
    &:not(.md-checked) .md-icon {
        border: none;
        border-radius: 0;
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        line-height: 1.7rem;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        &:after {
            border-radius: 0;
            border-width: 0;
            bottom: unset !important;
            color: @contrast-lighter;
            content: '\f05d';
            display: block !important;
            font-family: 'FontAwesome', sans-serif;
            font-size: 3.5rem;
            height: auto;
            left: 0;
            line-height: normal;
            position: absolute;
            right: 0;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            vertical-align: middle;
            width: auto;
        }
    }
    &.md-checked {
        .md-label {
            color: @contrast-lightest;
            span {
                border-bottom-color: @contrast-lightest;
            }
        }
        .md-icon {
            background-color: @contrast-dark;
            top: 0;
            &:after {
                border-radius: 0;
                border-width: 0;
                bottom: unset !important;
                color: @contrast-lightest;
                content: '\f058';
                display: block !important;
                font-family: 'FontAwesome', sans-serif;
                font-size: 3.5rem;
                height: auto;
                left: 0;
                line-height: normal;
                position: absolute;
                right: 0;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                vertical-align: middle;
                width: auto;
            }
        }
    }
}

.mat-checkbox {
    // this was default in angular material until v11
    display: inline !important;
}
