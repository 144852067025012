/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

.no-tooltip {
    padding-bottom: 1.7rem;
}

@media only screen and (max-width: 480px) {
    .stammdaten-view {
        .checkbox {
            /* overwrite global checkbox styles. margin-bottom from dv-input-container-question and
            checkbox would sum up here and lead to too big margins */
            margin-bottom: 0 !important;
        }
    }
}

.file-upload-error {
    margin-top: -2rem;
}

.margin-bottom-3rem {
    margin-bottom: 3rem;
}
