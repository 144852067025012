/*
 * Copyright (C) 2019 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
@import (reference) '../../../../style/variables.less';

footer {
    display: flex;
    font-size: 1.4rem;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    width: 100%;

    div {
        flex-basis: 0;
        flex-grow: 1;
    }

    .copyright {
        text-align: left;
    }

    .info {
        text-align: center;

        button {
            background: @white;
            border: 0;
            padding: 0;
            text-align: center;
            text-decoration: underline;
        }
    }

    .version {
        text-align: right;
    }

    &h1 {
        font-size: 2rem;
    }
}
