/*
 * Copyright (C) 2018 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

@import (reference) '../../../../style/variables.less';

.language-selector {
    height: 100%;
    padding-right: 2rem;
    position: relative;
    right: 0;
    text-align: center;
    top: 0;

    .language-button {
        background: transparent;
        border: 0;
        height: 100%;
        position: relative;
        right: 0;
        text-align: center;
        top: 0;
        a {
            color: @primary;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            height: 17px;
            letter-spacing: 1.5px;
            line-height: 17px;
            width: 129px;
        }
    }
}
