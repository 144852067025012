/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../variables.less';
@import (reference) '../mixins.less';

td,
th {
    background-clip: padding-box !important;
}

.table-responsive {
    border: none;
}

.row-fallnr {
    min-width: 8rem;
    width: 8rem;
}

.row-name {
    width: 10%;
}

.row-antrag {
    width: 7.5%;
}

.row-periode {
    width: 7.5%;
}

.row-geandert {
    width: 7.5%;
}

.eingangsdatum.row-status {
    width: 12.5%;
}

.row-angebot {
    width: 15%;
}

.row-inst {
    width: 15%;
}

.row-ver {
    width: 10%;
}

.row-action {
    width: 10%;
}

.table {
    margin-bottom: 2rem;
}

.table > tbody {
    & > tr,
    & > tr:first-child {
        &:hover {
            background: #fafafa;
            color: @contrast-darkest;
        }

        &:focus {
            outline: 2px solid @contrast-darkest;
            outline-offset: 0;
        }

        &.disabled-row,
        &.disabled-row:hover {
            background: @contrast;
            cursor: default;

            &:focus {
                outline: none;
            }

            & > td {
                border-color: @contrast-dark;
            }
        }
    }
}

table,
.table > tbody,
.table > thead,
.table > thead:first-child,
.dv-table mat-table {
    & > tr {
        & > td {
            .ebeguH3();
            border: 1px solid @contrast;
            border-left: none;
            border-right: none;
            color: @contrast-darkest;
            height: 6rem;
            line-height: 3rem;
            overflow: hidden;
            padding: 0 0.8rem 0;
            position: relative;
            text-overflow: ellipsis;
            vertical-align: middle;
            white-space: nowrap;

            .dvb-loading-button {
                text-align: end;
            }

            &.empty-table {
                .ebeguItalic();
            }
        }

        & > th,
        & > th:empty {
            .ebeguLabel();
            border: 1px solid @contrast;
            border-left: none;
            border-radius: 0;
            border-right: none;
            color: @contrast-darkest;
            height: 5rem;
            overflow: hidden;
            padding: 0 0.25rem 0;
            text-overflow: ellipsis;
            vertical-align: middle;

            span {
                .ebeguP();
                line-height: 0;
            }
        }
    }

    & > tr:first-child {
        & > th,
        & > th:empty {
            .ebeguLabel();
            border: 1px solid @contrast-darkest;
            border-left: none;
            border-radius: 0;
            border-right: none;
            color: @contrast-darkest;
            height: 4rem;
            padding: 0 0.8rem 0;
            vertical-align: middle;

            span {
                .ebeguP();
                line-height: 0;
            }
        }

        & > th:first-child {
            padding-left: 0;
        }

        .text-right {
            text-align: right;
        }
    }

    & > mat-header-row {
        min-height: auto;

        &:first-of-type.mat-mdc-header-row {
            border-bottom: 1px solid black;
            border-top: 1px solid black;
        }

        & > mat-header-cell:first-of-type {
            padding-left: 0;
        }

        &:first-of-type > mat-header-cell {
            .ebeguLabel();
            color: black;
            font-family:
                Open Sans,
                Arial,
                Helvetica,
                sans-serif;
            height: calc(4rem - 2px);
            overflow: hidden;
            padding: 0 0.25rem 0;
            text-overflow: ellipsis;
            text-transform: uppercase;
            vertical-align: middle;

            div > .mat-sort-header-content > span {
                color: black;
                font-size: 1.4rem;
                font-weight: 400;
                letter-spacing: 0.1rem;
                padding-left: 5px;
                text-align: left;
                text-transform: uppercase;
            }
        }

        &:nth-of-type(2) > mat-header-cell {
            .ebeguLabel();
            color: black;
            height: calc(5rem - 1px);
            overflow: hidden;
            padding: 0 0.25rem 0;
            text-overflow: ellipsis;
            vertical-align: middle;

            & > mat-form-field {
                & > .mat-mdc-text-field-wrapper {
                    & > div.mat-mdc-form-field-flex {
                        align-items: center;

                        & > .mat-mdc-form-field-infix {
                            border: 0;

                            & > input {
                                height: 3rem;
                            }
                        }
                    }
                    & > div.mat-mdc-form-field-underline {
                        display: none;
                    }
                }
            }
        }
    }
}

.dv-table.table-striped {
    mat-row:nth-child(2n + 1) {
        background-color: #f9f9f9;
    }
}

.gesuchsteller-dashboard-view {
    table {
        min-width: 100%;
    }

    .table > tbody {
        & > tr,
        & > tr:first-child {
            &:hover {
                background: rgba(220, 220, 220, 0.2);
            }
        }
    }

    table,
    .table > tbody,
    .table > thead,
    .table > thead:first-child {
        & > tr,
        & > tr:first-child {
            & > td {
                .ebeguLabel();
                border: 1px solid rgba(220, 220, 220, 0.2);
                border-left: none;
                border-right: none;
                color: @contrast-lightest;
                height: 5rem;
                padding: 0 0.8rem 0;
                vertical-align: middle;
            }

            & > th,
            & > th:empty {
                .ebeguH3();
                border: 1px solid @contrast-lightest;
                border-left: none;
                border-right: none;
                color: @contrast-lightest;
                height: 5rem;
                line-height: 3rem;
                padding: 0 0.8rem 0;
                position: relative;
                vertical-align: middle;

                span {
                    .ebeguP();
                    line-height: 0;
                }
            }
        }
    }
}

.ebegu-table {
    min-width: 65rem;
}

.table-fixed {
    table-layout: fixed;
}

.dv-table {
    table {
        width: 100%;
    }

    .mat-table {
        font-family:
            Open Sans,
            Arial,
            Helvetica,
            sans-serif;
    }

    tr.mat-mdc-header-row {
        height: 4rem;
    }

    mat-form-field {
        position: relative;
        width: 100%;
    }

    ::ng-deep .mat-mdc-form-field-infix {
        width: auto !important;
    }

    .fa-undo {
        &:hover {
            color: @primary;
            cursor: pointer;
        }
    }

    #titleText {
        display: inline-block;
    }

    #refreshIcon {
        display: inline-block;
        margin-left: 1rem;
    }

    .mat-mdc-select-panel1 {
        .ebeguLabel();
        background: #e5e5e5;
        color: black !important;
        font-family:
            Open Sans,
            Arial,
            Helvetica,
            sans-serif;
        font-size: 1.6rem !important;
        font-weight: 700;
        letter-spacing: normal;
    }

    .mat-mdc-form-field {
        .ebeguLabel();
        color: black !important;
        font-family:
            Open Sans,
            Arial,
            Helvetica,
            sans-serif;
        font-size: 1.6rem !important;
        font-weight: 700;
        line-height: normal;

        [matformfieldlineripple].mdc-line-ripple {
            display: none;
        }

        .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
                .mdc-text-field--textarea
            )
            .mat-mdc-form-field-infix {
            min-height: auto;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .mat-mdc-select {
            height: 3rem;
        }
    }

    .mat-autocomplete-panel {
        .ebeguLabel();
        background: #e5e5e5;
        color: black !important;
        font-family:
            Open Sans,
            Arial,
            Helvetica,
            sans-serif;
        font-size: 1.4rem !important;
        font-weight: 700;
        letter-spacing: normal;
    }

    .mat-option {
        font-size: 1.6rem;

        .mat-option-text {
            font-weight: normal;
        }
    }

    .dv-select-style {
        background: #e5e5e5;

        & > select {
            background: #e5e5e5;
        }
    }

    mat-cell {
        .ebeguFont-family();
        .ebeguH3();
        color: @contrast-darkest;
        // we need to declare this additionally in important because there is a global style that would otherwise overwrite
        // this and I don't konw where the global is applied and if we could remove it instead
        font-size: 1.4rem !important;
        height: 6rem;
        line-height: 3rem;
        margin: 0;
        overflow: hidden;
        padding: 0 0.25rem 0;
        position: relative;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;

        span:first-child {
            margin: 0 calc(0.8rem - 0.25rem) 0;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    mat-cell.mat-mdc-cell,
    mat-header-cell.mat-mdc-header-cell,
    mat-footer-cell {
        flex: 2;
    }

    mat-cell.mat-mdc-cell:last-of-type {
        padding-right: 6px;
    }

    [dvNewUserSelect] {
        width: 100%;
    }

    mat-row.mat-row {
        min-height: auto;
    }

    span.no-padding-left {
        padding-left: 0 !important;
    }

    span > label > mat-label {
        padding-left: 1rem;
    }

    mat-table
        > mat-header-row:nth-of-type(2)
        > mat-header-cell
        > mat-form-field
        > .mat-mdc-text-field-wrapper
        > div.mat-mdc-form-field-flex
        > .mat-mdc-form-field-infix
        input {
        padding-left: 5px;

        + span > label {
            padding-left: 5px;
            top: 2.8rem;
        }
    }

    .mat-mdc-text-field-wrapper {
        background-color: rgb(229, 229, 229);
        border-radius: 0;
        padding-bottom: 0;
        padding-left: 0.5rem;
        padding-right: 0;
    }

    .pointer-cursor {
        cursor: pointer;
    }

    mat-row mat-cell:first-of-type {
        padding-left: 0.8rem;
    }

    .mat-mdc-select-value {
        align-items: center;
        display: flex;
        padding-left: 5px;
        .ebeguFont-family();
        .ebeguLabel();
        .ebeguSemi-bold();
    }

    .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
        color: rgba(0, 0, 0, 0.54);
    }

    .mat-primary .mat-option.mat-mdc-selected:not(.mat-option-disabled) {
        color: rgba(0, 0, 0, 0.87);
    }

    .mat-focused .mat-mdc-form-field-label {
        color: rgba(0, 0, 0, 0.54);
    }
}
