.mat-mdc-menu-item,
.mat-mdc-menu-item:visited,
.mat-mdc-menu-item:link {
    .mat-mdc-menu-item-text {
        -moz-osx-font-smoothing: grayscale !important;
        -webkit-font-smoothing: antialiased !important;
        font-size: var(--mdc-typography-body1-font-size, 16px) !important;
        line-height: var(--mdc-typography-body1-line-height, 24px) !important;
        font-weight: var(--mdc-typography-body1-font-weight, 400) !important;
        letter-spacing: var(
            --mdc-typography-body1-letter-spacing,
            0.03125em
        ) !important;
        -webkit-text-decoration: var(
            --mdc-typography-body1-text-decoration,
            inherit
        ) !important;
        text-decoration: var(
            --mdc-typography-body1-text-decoration,
            inherit
        ) !important;
        text-transform: var(
            --mdc-typography-body1-text-transform,
            none
        ) !important;
        text-align: left !important;
        .MatMenuFontFamily();
    }
}

.mat-mdc-menu-panel,
.mat-mdc-menu-content,
.mat-mdc-menu-content span {
    .MatMenuFontFamily();
}
