/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
@import (reference) '../../../style/variables.less';

md-checkbox.checkbox-error {
    background: #ffe6e6;
}

.checkbox-bestaetigung {
    font-size: 0;
    height: auto;
    margin: 0 0 1rem;
}

.box-existing-mutationsmeldung {
    background-color: @danger-light;
    border-bottom-color: @danger-light;
    box-shadow: none;
    //margin-left: -5rem;
    //margin-right: -2.5rem;
    margin-bottom: 2.5rem;
    padding: 1rem 1.5rem 1rem 5rem;

    i {
        color: @danger;
        padding: 1rem 1rem 1rem 0;
    }
}

.eingewoehnung-hint {
    margin-bottom: 4rem;
}
